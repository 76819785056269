.subNotification {
  display: flex;
  align-items: center;
  column-gap: 8px;

  span {
    opacity: 0.5;
    font-size: 12px;
    line-height: 13px;
  }
}