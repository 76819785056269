.profileWrapper {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.authBox {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.usePhoto {
  width: 43px;
  height: 43px;
  border-radius: 12px;
  opacity: 0.7;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu, .menuActive {
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  padding: 1px 1px;
  box-shadow: 0px 7px 10px 0px #070C2B61;
  border-radius: 12px;
  height: 0;
  opacity: 0;
  pointer-events: none;
  transition: all .2s;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  z-index: 2;

  a, &__linkButton {
    padding: 11px 16px;
    background-color: #485A8E;
    width: 320px;
    opacity: 0;
    pointer-events: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    transition: all .2s;

    &:hover {
      background-color: #4d64a7;
    }
  }

  &__linkButton {
    opacity: 0;
    pointer-events: none;
    height: 46px;

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .themeWrapper {
    padding: 11px 16px;
    background-color: #485A8E;
    width: 320px;
    opacity: 0;
    pointer-events: none;
    border-radius: 10px;
    position: relative;
  }
}

.loader span {
  font-size: 6px;
}

.menuActive {
  padding: 14px 16px;
  height: auto;
  opacity: 1;
  pointer-events: all;

  a, .themeWrapper {
    opacity: 1;
    pointer-events: all;
  }

  &__linkButton {
    opacity: 1;
    pointer-events: all;
  }
}

.exit, .themeWrapper {
  margin-top: 16px;
}

.themeWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.theme {
  display: flex;
  align-items: center;
  column-gap: 10px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}

.changer__switcher {
  display: flex;
  align-items: center;
}

p {
  line-height: 14px;
}

.switcherBox, .switcherBoxActive {
  width: 32px;
  height: 20px;
  border-radius: 50px;
  background-color: #78788029;
  position: relative;
  transition: all .2s;
}

.switcherFalse, .switcherTrue {
  width: 17px;
  height: 17px;
  background-color: #fff;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 3px 1px 0px #0000000F;
  box-shadow: 0px 3px 8px 0px #00000026;
  left: 1px;
  top: 2px;
  transition: all .2s;
}

.switcherTrue {
  left: calc(100% - 19px);
}