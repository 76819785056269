.spot {
  padding: 0 30px 40px;
  //background-color: #04081B;
}

.spotRightBox {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  border-radius: 16px;
}

.mainContent {
  display: flex;
  column-gap: 8px;
  align-items: stretch;
}

.leftBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__content {
    display: flex;
    column-gap: 8px;
  }
}

.chartWrapper {
  position: relative;
  width: 100%;
  background-color: #16121F;
  border-radius: 16px;
  overflow: hidden;
  height: 542px;
}

.spotBookWrapper {
  max-width: 276px;
  position: relative;
  width: 100%;
  border-radius: 16px;
  //padding-bottom: 38px;
}

.showMobOrders {
  border-radius: 8px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  img {
    transition: all .15s;
  }
}

.contentChanger, .contentChangerLight {
  height: 37px;
  border-radius: 8px;
  display: flex;
  column-gap: 8px;
  margin-bottom: 8px;

  &__changer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    p {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      border-radius: 8px;
    }
  }
}

.formSkeleton {
  max-width: 286px;
  min-width: 286px;
  width: 100%;
  border-radius: 16px;
  display: block;
  height: 100%;
}

.contentChanger__changer:has(input:checked) p {
  background-color: #2359B8 !important;
}

.contentChangerLight__changer:has(input:checked) p {
  background-color: #3866B7 !important;
  color: #fff;
}

@media only screen and (max-width: 1920px) {
  .spot {
    max-width: 1600px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1280px) {
  .spot {
    padding: 40px 16px;
  }
  .chartWrapper {
    //height: 605px;
  }

  .spotBookWrapper {
    max-height: 676px;
    height: 100%;
  }

  .spotBookWrapper {
    max-width: 286px;
  }
}

@media only screen and (max-width: 743px) {
  .spot {
    padding: 24px 16px 80px;
  }

  .chartWrapper {
    overflow: inherit;
  }

  .mainContent {
    display: block;
  }

  .spotBookWrapper {
    width: 100%;
    min-width: 100%;
  }

  .mobActions {
    display: flex;
    column-gap: 8px;
    margin-top: 8px;

    button {
      max-width: 50%;
      width: 100%;
      border-radius: 8px;
      height: 37px;

      span {
        color: #fff !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
    }

    &__buy {
      background-color: #138850;
    }

    &__sell {
      background-color: #F43443;
    }
  }

  .leftBox {

    &__content {
      padding-bottom: 70px;
    }
  }
}

.comparisonMob > div {
  margin-top: 10px;
}

