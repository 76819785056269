.filtersWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
}

.filter {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__label {
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
  }
}

.option {
  display: flex;
  align-items: center;
  column-gap: 8px;

  span {
    margin-top: 4px;
  }

  &__token {
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    opacity: .3;
  }

  img {
    width: 26px;
    height: 26px;
  }
}

@media only screen and (max-width: 743px) {
  .filtersWrapperShadowHide, .filtersWrapperShadowShow {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 51;
    transition: all .2s;
  }

  .filtersWrapperShadowHide {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 51;
    background-color: transparent !important;
    pointer-events: none;
  }

  .filtersWrapper {
    position: fixed;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    bottom: 0;
    left: 0;
    height: auto;
    z-index: 52;
    padding: 16px 24px 24px 16px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    transition: all .2s;

    &__close {
      width: 24px;
      margin-left: calc(100% - 24px);
    }

    &__action {
      button {
        padding: 14px 34px;
        border-radius: 8px !important;
        margin-top: 12px;

        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 14px;
        }
      }
    }
  }

  .option {
    span {
      margin-top: 0;
    }
  }

  .filter {
    &__label {
      font-size: 13px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: -0.03em;
      opacity: .5;
    }
  }

  .tokenChangerButtons {
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
    flex-wrap: wrap;
    overflow-x: auto;

    &__toggle {
      width: auto;

      span {
        font-size: 12px;
        line-height: 10px;
        padding: 14px;
        display: block;
        width: 100%;
        height: 100%;
      }

      p {
        font-size: 12px;
        line-height: 10px;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}


@media only screen and (min-width: 743px) {
  .filtersWrapperShadowHide, .filtersWrapperShadowShow {
    background-color: transparent !important;
  }
}