.mainBalances {
  margin-top: 44px;
}

.search {
  display: flex;
  align-items: center;
  column-gap: 38px;
  justify-content: space-between;
  position: relative;

  input {
    border-radius: 18px;
    background-color: transparent;
    height: 48px;
    width: 100%;
    padding-left: 60px;
  }

  img {
    position: absolute;
    left: 22px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.changer {
  min-width: 182px;
  display: flex;
  align-items: center;
  column-gap: 14px;

  &__switcher {
    display: flex;
    align-items: center;
  }

  p {
    line-height: 14px;
  }
}

.switcherBox, .switcherBoxActive {
  width: 32px;
  height: 20px;
  border-radius: 50px;
  position: relative;
  transition: all .2s;
}

.switcherFalse, .switcherTrue {
  width: 18px;
  height: 18px;
  background-color: #fff;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 3px 8px 0px #00000026;
  left: 1px;
  top: 1px;
  transition: all .2s;
}

.switcherTrue {
  left: calc(100% - 19px);
}

@media only screen and (min-width: 743px) {
  .mainBalances {
    background-color: transparent !important;
  }
}

@media only screen and (max-width: 1024px) {
  .mainBalances {
    padding: 16px 16px;
    border-radius: 8px;
    margin-top: 37px;
  }

  .search {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 18px;

    input {
      height: 44px;
    }

    img {
      position: absolute;
      left: 14px;
      top: 10px;
      transform: inherit;
    }
  }
}

@media only screen and (max-width: 743px) {
  .mainBalances {
    padding: 16px 16px;
    border-radius: 8px;
    margin-top: 0;
  }
}