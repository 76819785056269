.quantityRange {
  margin-top: 16px;

  input {
    width: 100%;
  }

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: #686B82;
    cursor: pointer;
    height: 4px;
    border-radius: 8px;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    //margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: none;
    border: none;
  }

  input[type=range]::-moz-range-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    box-shadow: none;
    border: none;
  }

  input[type=range]::-ms-thumb {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    box-shadow: none;
    border: none;
  }

  &__max {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;

    span {
      color: #686B82;
      font-size: 11px;
      font-weight: 400;
      line-height: 13px;
    }
  }
}