.creditBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 6px;
  margin: 6px 0;

  button:disabled {
    opacity: 0.3;
    cursor: inherit;
  }
}

.boxHide {
  opacity: 0.3;
  pointer-events: none;
  cursor: inherit;
}

.leverageBtn {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 11px 10px 11px 12px;

  img {
    margin-top: 1px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding-top: 1px;
  }
}