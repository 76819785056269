.whyCustomers {
  padding: 180px 126px 0;
  position: relative;
}

.decor {
  position: absolute;
  right: 0;
  top: 20px;
  max-width: 1412px;
  max-height: 995px;
  pointer-events: none;
}

.header {
  margin-bottom: 218px;
  position: relative;

  h2 {
    font-size: 65px;
    line-height: 57px;
    font-weight: 500;
    max-width: 540px;
    margin-bottom: 45px;
  }

  &__subTitle {
    font-size: 18px;
    line-height: 25px;
    max-width: 440px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-bottom: 240px;
}

.box {
  padding: 33px 82px 52px 40px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    margin-bottom: 24px;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 24px;
  }

  &__text {
    line-height: 22px;
    opacity: 0.5;
  }
}

.top, .bottom {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.bottom {
  top: inherit;
  bottom: 0;
}


.left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 0.9;
}

.right {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1450px) {
  .decor {
    position: absolute;
    right: -20px;
    top: 20px;
    max-width: 1250px;
    max-height: 900px;
    pointer-events: none;
  }

  .header {
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 1280px) {
  .whyCustomers {
    padding: 160px 66px 0;
    position: relative;
  }

  .top, .bottom {
    width: 60%;
  }

  .decor {
    position: absolute;
    right: -20px;
    top: 20px;
    max-width: 1000px;
    max-height: 900px;
    pointer-events: none;
  }

  .header {
    margin-bottom: 144px;

    h2 {
      font-size: 44px;
      line-height: 50px;
      margin-bottom: 32px;
    }

    &__subTitle {
      font-size: 16px;
      line-height: 20px;
      max-width: 440px;
      opacity: 0.7;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .whyCustomers {
    padding: 80px 0 0;
  }

  .decor {
    position: absolute;
    right: -80px;
    top: -3%;
    width: 110%;
    max-height: 100%;
    pointer-events: none;
  }

  .header {
    h2 {
      font-size: 32px;
      line-height: 28px;
      margin-bottom: 26px;
      max-width: 238px;
    }

    &__subTitle {
      font-size: 15px;
      line-height: 21px;
      max-width: 333px;
      opacity: 0.5;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 200px;
  }

  .box {
    padding: 33px 82px 77px 40px;
    max-width: 85%;

    &__text {
      font-size: 15px;
      line-height: 21px;
      opacity: 0.5;
    }
  }
}

@media only screen and (max-width: 900px) {
  .decor {
    top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    grid-gap: 55px;
  }

  .decor {
    top: -160px;
    right: 0;
    left: -62px;
    width: calc(100% + 160px);
  }

  .header {
    margin-bottom: 92px;

    h2 {
      font-size: 24px;
      line-height: 21px;
      margin-bottom: 26px;
      max-width: 180px;
    }

    &__subTitle {
      font-size: 14px;
      line-height: 20px;
      opacity: 0.5;
      max-width: 100%;
      padding-right: 10px;
    }
  }

  .box {
    padding: 0 12px 0;
    max-width: 100%;

    img {
      width: 32px;
      height: 32px;
    }

    &__title {
      font-size: 18px;
      line-height: 16px;
      margin-bottom: 16px;
    }

    &__text {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .decor {
    top: -50px;
    right: 0;
    left: -62px;
    width: calc(100% + 160px);
  }
}
