.spotTransferWrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(5, 22, 52, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 51;
}

.spotTransfer {
  padding: 40px;
  background-color: #fff;
  border-radius: 12px;
  max-width: 880px;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }
}

.content {
  max-width: 500px;
  width: 100%;
}

.changerFields {
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    margin-bottom: 10px;
    display: block;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    width: 100%;
    height: 50px;
    padding-left: 16px;
    border-radius: 11px;

    display: flex;
    align-items: center;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;

    span {
      margin-bottom: 0;
    }
  }
}

.coinChanger {
  display: flex;
  align-items: center;
  margin: 10px 0;
  column-gap: 26px;

  button {
    height: 50px;
    width: 155px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
  }

  &__circle {
    width: 13px;
    height: 13px;
    border: 1px solid #878D99;
    border-radius: 50%;
    margin-right: 6px;
  }
  &__coin {
    margin-bottom: -1px;
  }
}

.fieldBox, .fieldBoxError {
  height: 50px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 6px 16px;

  input {
    height: 20px;
    width: 100%;
    background-color: transparent;
  }
}

.allBox {
  background-color: #1C243D;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    padding: 0 11px;
  }

  button {
    width: 40px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    span {
      padding: 0;
    }
  }
}

.actions {
  margin: 24px 0 16px;
  display: flex;
  align-items: center;
  column-gap: 16px;

  button:disabled {
    opacity: 0.4;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
  }

  &__confirm {
    border-radius: 8px;
    width: 100px;
    height: 50px;
  }
}

.borrowNotification span {
  white-space: pre-line;
  line-height: 9px;
}

.fieldBoxError {
  border: 1px solid #941F1F !important;
}

@media only screen and (max-width: 960px) {
  .spotTransfer {
    max-width: 660px;
  }
}

@media only screen and (max-width: 743px) {
  .spotTransferWrapper {
    align-items: flex-end;
  }

  .spotTransfer {
    max-width: 100%;
    height: auto;
    padding: 16px 16px 30px 16px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    &__header {
      margin-bottom: 16px;

      h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .content {
      max-width: 100%;
    }
  }

  .coinChanger {
    button {
      height: 45px;
    }
  }

  .changerFields {
    span {
      opacity: 0.5;
    }

    p {
      height: 45px;
    }
  }

  .fieldBox, .fieldBoxError {
    height: 45px;
    padding: 3px 16px;
  }

  .allBox {
    button {
      width: 38px;
      height: 37px;
    }
  }

  .actions {
    &__confirm {
      height: 45px;
    }
  }
}
