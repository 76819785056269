.newsletter {
  padding: 260px 126px 165px;
  position: relative;
  z-index: 2;

  h3 {
    max-width: 490px;
    font-size: 65px;
    font-weight: 500;
    margin-bottom: 72px;
  }

  &__text {
    opacity: 0.5;
    font-size: 18px;
    line-height: 25px;
    max-width: 420px;
    margin-bottom: 72px;
  }

  &__send {
    font-weight: 500;
    font-size: 48px;
    line-height: 63px;
    width: calc(100% - 230px);
    padding-bottom: 18px;
    opacity: 0.5;
    border-bottom: 1px solid #FFFFFF;
  }

  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;
    max-width: 890px;

    input {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      background-color: transparent;
      height: 80px;
      max-width: 660px;
      width: 100%;
      padding-left: 8px;
      color: #fff;
      font-size: 36px;
      font-weight: 500;
      line-height: 46px;
    }

    input::placeholder {
      font-size: 48px;
      font-weight: 500;
      line-height: 63px;
      color: rgba(255, 255, 255, 0.5);
    }

    button {
      height: 79px;
      width: 190px;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      background-color: #2359B8;
      border-radius: 19px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .newsletter {
    padding: 260px 126px 165px;

    h3 {
      font-size: 44px;
      line-height: 50px;
      margin-bottom: 40px;
    }

    &__send {
      font-weight: 500;
      font-size: 40px;
      line-height: 50px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .newsletter {
    padding: 202px 0 165px;

    h3 {
      font-size: 32px;
      line-height: 63px;
      margin-bottom: 16px;
    }

    &__action {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 40px;

      input {
        height: 66px;
        padding-bottom: 9px;
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
      }

      input::placeholder {
        font-size: 32px;
        font-weight: 500;
        line-height: 63px;
        color: rgba(255, 255, 255, 0.5);
      }

      button {
        width: 190px;
        height: 70px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .newsletter {
    padding: 110px 0 120px;

    h3 {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px;
    }

    &__text {
      font-size: 15px;
      line-height: 21px;
      max-width: 100%;
      margin-bottom: 48px;
    }

    &__action {
      row-gap: 24px;

      input {
        height: 35px;
        padding-bottom: 9px;
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
      }

      input::placeholder {
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.5);
      }

      button {
        width: 100%;
        height: 57px;
        font-size: 15px;
      }
    }
  }
}