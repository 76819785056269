.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  //align-items: center;
}

.swiper.swiper-horizontal.swiper-backface-hidden.mySwiper {
  padding-bottom: 96px;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
}

.swiper-pagination-bullet {
  background-color: transparent;
  opacity: 1;
  border: 1px solid #3E78DC;
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 9px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 9px));
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #3E78DC;
}

@media only screen and (max-width: 1024px) {
  .css-18p7s5a-MuiResponsiveChart-container {
    max-width: 280px;
  }

  .MuiChartsLegend-mark {
    width: 7px;
    height: 7px;
    transform: translate(7px, 7px);
    border-radius: 2px;
  }
}

@media only screen and (max-width: 743px) {
  .swiper.swiper-horizontal.swiper-backface-hidden.mySwiper {
    padding-bottom: 16px;
  }
}

.css-y8gjtw-MuiResponsiveChart-container {
  max-width: 290px !important;
}