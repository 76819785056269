* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
}

textarea {
  resize: none;
}

a, button {
  -webkit-tap-highlight-color: transparent;
}

button {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Aeonik';
}

#root {
  height: 100%;
}

a, p, span, a, li, h1, h2, h3, h4, h5, h6 {
  color: #fff;
  line-height: 1.2em;
}

.closer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  cursor: default;
}

.loadingWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 8;
}

.loadingWrapper span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.selectFieldBox {
  position: relative;
  min-height: 50px;

  .loadingLocal span {
    font-size: 6px;
  }
}

.loadingLocal {
  position: absolute;
  border-radius: 8px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 8;
}

.loadingLocal span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@media only screen and (min-width: 1920px) {
  .wrapper {
    max-width: 1600px;
  }

  .wrapper:has(.spot) {
    max-width: 100%;
  }
}


//DARK THEME


.dark {
  background-color: #0A0C24;

  .boxLoader {
    background: linear-gradient(45deg, rgb(8, 9, 28), #16121F, #332349);
    background-size: 500% 500%;
    animation: gradient 2.5s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  input {
    color: #fff;
  }

  a, p, span, a, li, h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }

  .box {
    background-color: #171E33;
  }

  .spotBg {
    background-color: #16121F;
  }

  .spotBoxBg {
    background-color: #201D2B;
  }

  .mobBox {
    background-color: #131B38;
  }

  .mobNav {
    background-color: #1B263B;
  }

  .mobSecondBox {
    background-color: #172144;
  }

  @media only screen and (max-width: 1024px) {
    .mobLinkBtn {
      background-color: #333F61;
    }
  }

  .secondBox {
    background-color: #303953;
  }

  .thirdBox {
    background-color: #202942;
  }

  .addressBox {
    background-color: #24324D;
  }

  .copyButton {
    background-color: #132240;

    img {
      opacity: .5;
    }
  }

  .changeDot span {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  .changeDotActive span {
    background-color: #2359B8;
    border: 1px solid #2359B8;
  }

  @media only screen and (max-width: 1024px) {
    .popupBox {
      background-color: #0F1B33;
    }
  }

  .block {
    background-color: #1C243D;
  }

  .homeHeader {
    background-color: #010104;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      width: calc(100% + 20000px);
      background-color: #010104;
      left: -5000px;
      height: 100%;
      z-index: -1;
    }

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: #0C1223;
      z-index: -1;
    }
  }

  .mainMenuBtn {
    background-color: #333F61;
    transition: all .2s;

    &:hover {
      background-color: #293350;
    }
  }

  .mainMenuBtnActive {
    background-color: #2359B8;
    transition: all .2s;

    &:hover {
      background-color: #184999;
    }
  }

  .eventHead {
    background-color: #303953;
  }

  .giftBg {
    background: linear-gradient(271.09deg, #1A2542 10.99%, #243B76 104.86%);

    a, p, span, a, li, h1, h2, h3, h4, h5, h6 {
      color: #fff;
    }
  }

  .footerBg {
    background: rgb(8, 9, 28);
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      width: calc(100% + 20000px);
      background: rgb(8, 9, 28);
      left: -5000px;
      height: 100%;
    }
  }

  .customField {
    border: 1px solid #FFFFFF80;
    border-radius: 11px;
    background-color: transparent;
  }

  /* Chrome, Safari, Edge, Opera */
  .customField::-webkit-outer-spin-button,
  .customField::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  .customField[type=number] {
    -moz-appearance: textfield;
  }

  .button {
    background-color: #2359B8;
    border-radius: 18px;
  }

  .button:disabled {
    background-color: #858891;
    opacity: .5;
  }

  .linkActive {
    background-color: #33436D;
  }

  .customBtn {
    width: 100%;
    border-radius: 18px;
    height: 50px;
    background-color: #2359B8;
  }

  .toggleRadio {
    border-radius: 8px;
    position: relative;
    height: 30px;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 8px;
      cursor: pointer;
    }

    p {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #333F61;
      pointer-events: none;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .15s;
    }
  }

  .toggleRadio:has(input:hover) p {
    background-color: #2c3755;
  }

  .toggleRadio:has(input:checked) {
    p {
      background-color: #2359B8;
    }

    &:hover p {
      background-color: #184999;
    }
  }

  .mainContent {
    .question {
      background-color: #303953;
    }

    .allBtn {
      background-color: #1C243D;

      button {
        background-color: #333F61;
      }
    }
  }

  .blueBtn {
    border-radius: 19px;
    background-color: #2359B8;
    position: relative;
    transition: all .15s;

    &:disabled {
      opacity: .4;
      cursor: default;
    }

    &:hover {
      position: relative;
      background-color: #184999;
    }
  }

  .notification {
    background-color: #303953;
  }

  .notificationItem {
    background-color: #303953;
  }

  .footerNavigation {
    background-color: #121A32;
  }

  .mobMenu {
    background-color: #212B48;

    h4 {
      color: #fff;
    }
  }

  .mobMenuShow {
    background-color: #15234B;
  }

  .skill {
    background: #191D2E;
  }

  .fieldCode input {
    color: #fff !important;
  }
}


//LIGHT THEME


.light {
  background-color: rgba(237, 237, 241, 1);

  .boxLoader {
    background: linear-gradient(45deg, #fff, rgb(223, 223, 232), #F6F5F9);
    background-size: 700% 700%;
    animation: gradientlight 2s ease infinite;
  }

  @keyframes gradientlight {
    0% {
      background-position: 0 70%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 70%;
    }
  }

  input {
    color: #fff;
  }

  a, p, span, a, li, h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }

  .box {
    background-color: #fff;
  }

  .spotBg {
    background-color: #fff;
  }

  .spotBoxBg {
    background-color: #EDEDF0;
  }

  .mobBox {
    background-color: #F7F6FE;
  }

  .mobNav {
    background-color: #F7F6FE;
  }

  .mobSecondBox {
    background-color: #F2F0FF;
  }

  @media only screen and (max-width: 1024px) {
    .mobLinkBtn {
      background-color: #F7F6FE;
    }
  }

  .secondBox {
    background-color: #EDF3FF;
  }

  .thirdBox {
    background-color: #EDF3FF;
  }

  .addressBox {
    background-color: #EDF3FF;
  }

  .copyButton {
    background-color: #485A8E;

    img {
      opacity: 1;
    }
  }

  .changeDot span {
    border: 1px solid rgba(35, 89, 184, 0.5);
  }

  .changeDotActive span {
    background-color: #2359B8;
    border: 1px solid #2359B8;
  }

  @media only screen and (max-width: 1024px) {
    .popupBox {
      background-color: #F4F5FA;
    }
  }

  .block {
    background-color: #EDF3FF;
  }

  .secondBlock {
    background-color: #FAFAFA;
  }

  .homeHeader {
    background-color: #1D285E;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      width: calc(100% + 20000px);
      background-color: #1D285E;
      left: -5000px;
      height: 100%;
      z-index: -1;
    }

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: #fff;
      z-index: -1;
    }
  }

  .mainMenuBtn {
    background-color: #2359B8;
    transition: all .2s;

    &:hover {
      background-color: #1c50aa;
    }
  }

  .mainMenuBtnActive {
    background-color: #3D6EBE;
    transition: all .2s;

    &:hover {
      background-color: #457ad2;
    }
  }

  .eventHead {
    background-color: #EDF3FF;

    p, span {
      color: #333F61;
    }
  }

  .giftBg {
    background: rgba(49, 82, 158, 1);

    a, p, span, a, li, h1, h2, h3, h4, h5, h6, input {
      color: #fff !important;
    }
  }

  .footerBg {
    background: rgba(19, 35, 65, 1) !important;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      width: calc(100% + 20000px);
      background: rgba(19, 35, 65, 1) !important;
      left: -5000px;
      height: 100%;
    }
  }

  .customField {
    border: 1px solid #333F614D;
    border-radius: 11px;
    background-color: transparent;
  }

  .button {
    background-color: #2359B8;
    border-radius: 18px;
  }

  .linkActive {
    background-color: #33436D;
  }

  .customBtn {
    width: 100%;
    border-radius: 18px;
    height: 50px;
    background-color: #2359B8;

    span {
      color: #fff !important;
    }
  }

  .toggleRadio {
    border-radius: 8px;
    position: relative;
    height: 38px;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 8px;
      cursor: pointer;
    }

    p {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #EDF3FF;
      pointer-events: none;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s;
    }
  }

  .toggleRadio:has(input:hover) p {
    background-color: #dce5f6;
  }

  .toggleRadio:has(input:checked) {
    p {
      background-color: #3c4c7b;
      color: #fff;
      transition: all .2s;
    }

    &:hover p {
      background-color: rgba(60, 76, 123, 0.85);
    }
  }

  .mainContent {
    input {
      color: #333F61;
    }

    a, p, span, a, li, h1, h2, h3, h4, h5, h6 {
      color: #333F61;
    }

    .question {
      background-color: #EDF3FF;
    }

    .allBtn {
      background-color: #E4E8EF;

      button {
        background-color: #485A8E;

        span {
          color: #fff;
        }
      }
    }
  }

  .blueBtn {
    border-radius: 8px;
    background-color: #485A8E;
    transition: all .2s;

    &:hover {
      background-color: #395ec6;
    }

    span {
      color: #fff;
    }
  }

  .notification {
    background-color: #EDF3FF;
  }

  .notificationItem {
    background-color: #FFFFFF;
  }

  .footerNavigation {
    background-color: #fff;
    border-top: #333F611A;
  }

  .mobMenu {
    background-color: #fff;

    h4 {
      color: #333F61;
    }
  }

  .mobMenuShow {
    background-color: #fff;
  }

  .skill {
    background: #5372A5;
  }

  .skill-set .skill {
    background: #003C9545;
  }

  .fieldCode input {
    color: #333F61 !important;
  }
}

body:has(.light.spot) .homeHeader {
  background-color: #F6F5F9;

  a, span {
    color: #333F61;
    opacity: 1;
  }

  &:before {
    background-color: #F6F5F9;
  }
}

body:has(.light.spot) .homeHeader .headerBalance {
  color: #333F61;
}

body:has(.spot) .light {
  background-color: #F6F5F9;
}

body:has(.light.spot) .auth {
  background-color: #F6F5F9 !important;
}

body:has(.light.spot) .footerBg {
  background-color: #F6F5F9 !important;

  ul li, h5 {
    color: #333F61;
    opacity: 1;
  }

  &:before {
    background-color: #F6F5F9 !important;
  }
}

.rangeCircle {
  position: absolute;
  top: 8px;
  height: 12px;
  width: 12px;
  border: 2px solid #5B5962;
  border-radius: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: #191424;
  z-index: 10;
  pointer-events: none;

  &__1 {
    left: 2px;
  }

  &__2 {
    left: 16.5%;
  }

  &__3 {
    left: 32%;
  }

  &__5 {
    left: 47.5%;
  }

  &__10 {
    left: 63.5%;
  }

  &__15 {
    left: 80%;
  }

  &__50 {
    left: 100%;
  }
}

.light.spot {
  background-color: #F6F5F9;
}

.rangeCircleComplete {
  border: 2px solid #fff;
  background-color: #fff;
}

.rangeEmpty {
  position: absolute;
  left: 0;
  top: 7px;
  width: 100%;
  height: 2px;
  background-color: #5B5962;
  opacity: 0.3;
  pointer-events: none;
  z-index: 5;
}

.rangeFull {
  position: absolute;
  left: 0;
  top: 7px;
  height: 2px;
  background-color: #fff;
  pointer-events: none;
  z-index: 6;
}

.thumb {
  position: absolute;
  top: 8px;
  pointer-events: none;
  height: 12px;
  width: 12px;
  border: 3px solid #fff;
  border-radius: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: #fff;
  z-index: 11;
}

@media only screen and (max-width: 1024px) {
  .tradingview-widget-container {
    height: 605px;
  }
}

@media only screen and (max-width: 743px) {
  .dark.spot {
    background-color: #04081B !important;
  }

  .dark .historyItem {
    background-color: #0D182E;
  }

  .light .historyItem {
    background-color: #F7F6FE;
  }

  .dark .spotBg.spotOrderForm {
    background-color: #04081B !important;
  }

  .light .spotBg.spotOrderForm {
    background-color: #fff !important;
  }

  .dark .spotFooter {
    background-color: #04081B !important;
  }

  .light .spotFooter {
    background-color: #fff !important;
  }

  .dark .spotHeader {
    background-color: #04081B !important;
  }

  .light .spotHeader {
    background-color: #fff !important;
  }

  .dark .tab {
    background-color: #333F63;
    transition: all .15s;
  }

  .dark .tabActive {
    background-color: #465583;
  }

  .light .tab {
    background-color: #fff;
    transition: all .15s;
    border: 1px solid transparent;

    p {
      color: #333F61;
      opacity: 1;
    }
  }

  .light .tabActive {
    background-color: #fff;
    border: 1px solid #3866B7;
  }
}

body:has(.spot.dark) {
  .homeHeader {
    background-color: #10091B;

    &:before {
      background-color: #10091B;
    }
  }

  .auth {
    background-color: #10091B !important;
  }

  .spot.dark {
    background-color: #10091B;
  }

  .wrapper.dark {
    background-color: #10091B !important;
  }
}

body:has(.dark .notificationsPage) .homeHeader {
  background-color: #0A0C24 !important;

  &:before, &:after {
    background-color: #0A0C24 !important;
  }
}


@media only screen and (max-width: 743px) {
  body:has(.spot.dark) .spot.dark {
    background-color: #04081B !important;
  }
}

@media only screen and (min-width: 743px) {
  body:has(.dark) {
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #212B48;
    }

    ::-webkit-scrollbar-thumb {
      background: #0F1630;
      cursor: pointer;
      transition: all 0.3s;
    }
  }
  body:has(.light) {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #E7F0FF;
    }

    ::-webkit-scrollbar-thumb {
      background: #3D6EBE;
      border-radius: 8px;
    }
  }
}