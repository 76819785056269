.recentTrades {
}

.formSkeleton {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  border-radius: 16px;
  display: block;
  height: 100%;
}