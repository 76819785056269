.amountField {
  margin-top: 20px;

  &__field {
    position: relative;
  }
}

.amount, .amountError {
  height: 50px;
  width: 100%;
  padding-left: 20px;
  padding-right: 114px;
}

.amountError {
  border-color:  rgba(255, 0, 0, 0.7) !important;
}

.errorText {
  color: rgba(255, 0, 0, 0.7) !important;
  opacity: 1 !important;
}

.balances {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;

  div {
    position: inherit;
    width: 80px;
    height: 15px;

    span {
      font-size: 4px;
    }
  }

  p {
    font-size: 12px;
    line-height: 15px;
    opacity: 0.3;
  }
}