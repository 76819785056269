.wrapper {
  padding: 45px 82px 40px;
  margin: 0 auto 40px;
  overflow: hidden;
}

.logoFooter {
  margin-bottom: -350px;
  padding: 0 0 0;
  position: relative;
  z-index: 1;

  img {
    object-fit: contain;
    width: 120%;
    margin-left: -10%;
  }
}

@media only screen and (max-width: 1280px) {
  .logoFooter {
    img {
      width: 130%;
      margin-left: -17%;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .logoFooter {
    img {
      width: 130%;
      margin-left: -20%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    padding: 20px 32px 40px;
  }
  .logoFooter {
    margin-bottom: -200px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 27px 16px 40px;
  }
}

@media only screen and (max-width: 600px) {
  .logoFooter {
    margin-bottom: -90px;
  }
}