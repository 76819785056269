.mobileChooser {
  height: 42px;
  background-color: #171E33;
  border-radius: 12px;
  width: 100%;
  margin: 52px 0;
  display: flex;
}

.chooser {
  width: 50%;
  border-radius: 12px;
  background-color: transparent;
  position: relative;

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  p {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    font-size: 14px;
    line-height: 12px;
    cursor: pointer;
    transition: all .15s;
  }
}

.chooser:has(input:checked) {
  p {
    background-color: #3866B7;
    border-radius: 12px;
  }
}