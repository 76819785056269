.walletConnect {
  display: flex;
  justify-content: center;
  column-gap: 18px;
  margin: 32px 65px 300px;

  &__leftBox {
    width: 100%;
    padding: 22px 16px;
    background-color: #171E33;
    border-radius: 20px;
    max-width: 450px;
  }

  &__rightBox {
    max-width: 400px;
    width: 100%;
    padding: 22px 16px;
    background-color: #171E33;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .walletConnect {
    display: block;
    margin: 55px 90px 200px 90px;

    &__leftBox {
      max-width: 756px;
      width: 100%;
      padding: 0;
      background-color: transparent !important;
      border-radius: 20px;
    }
  }
}

@media only screen and (max-width: 743px) {
  .walletConnect {
    margin: 22px 16px 100px 16px;

    &__leftBox {
      padding-bottom: 20px;
    }
  }
}