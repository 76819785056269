.notification {
  max-width: 100%;
  display: flex;
  padding: 13px 8px 13px 15px;
  column-gap: 8px;
  align-items: center;
  background-color: #303953;
  border-radius: 18px;

  p {
    font-size: 15px;
    line-height: 13px;
  }
}