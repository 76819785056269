.login {
  padding: 90px 80px 130px 214px;
  display: flex;
  //overflow: hidden;
  min-height: 100vh;

  h3 {
    font-size: 40px;
    line-height: 44px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.leftBox {
  max-width: 360px;
  position: relative;
  z-index: 1;
}

.logo {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 900;
  font-family: "Audiowide", serif;
  display: block;
}

.subtitle {
  display: block;
  opacity: 0.5;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 16px;
}

.field {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  label {
    margin-top: 24px;
    color: #fff;
    font-weight: 500;
    line-height: 18px;
  }

  &__label {
    margin-top: 24px;
    color: #fff;
    font-weight: 500;
    line-height: 18px;
  }

  input {
    border: 1px solid #FFFFFF80;
    background-color: transparent;
    height: 55px;
    border-radius: 11px;
    padding-left: 20px;
    color: #fff;
    font-size: 12px;
    line-height: 15px;
  }
}

.wrong input {
  border-color: red;
}

.action {
  margin-top: 48px;

  button {
    height: 54px;
    background-color: #33436D;
    width: 100%;
    border-radius: 13px;

    span {
      font-weight: 700;
      line-height: 18px;
    }
  }

  button:disabled {
    opacity: 0.4;
    cursor: unset;
  }
}

.toRegister {
  margin-top: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;

  span {
    font-size: 12px;
    opacity: 0.3;
  }

  a {
    font-size: 12px;
    text-decoration: underline;
  }
}

.rightBox {
  position: relative;
}

.rightBox img {
  position: absolute;
  left: -150px;
  top: -405px;
  width: 1350px;
  height: auto;
}

.restore {
  margin-top: 8px;
  display: flex;
  column-gap: 6px;

  span {
    opacity: 0.5;
    font-size: 12px;
    line-height: 15px;
  }

  button {
    p {
      font-size: 12px;
      line-height: 15px;
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .login {
    padding: 90px 80px 130px 214px;
    height: 100vh;
  }

  .rightBox img {
    position: absolute;
    left: -150px;
    top: -360px;
    width: 1200px;
    height: auto;
  }
}


@media only screen and (max-width: 1024px) {
  .login {
    padding: 70px 97px 50px 73px;
    height: 100%;

    h3 {
      font-size: 40px;
      line-height: 44px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  .logo {
    margin-bottom: 40px;
  }

  .rightBox img {
    position: absolute;
    left: -140px;
    top: -155px;
    width: 900px;
    height: auto;
  }

  .action {
    margin-top: 24px;
  }

  .leftBox {
    max-width: 300px;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .login {
    padding: 24px 32px;
    height: 100%;

    h3 {
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .leftBox {
    max-width: 100%;
    width: 100%;
  }

  .rightBox img {
    position: absolute;
    left: -140px;
    top: -155px;
    width: 900px;
    height: auto;
  }

  .field {
    input {
      height: 44px;
    }

    label {
      margin-top: 10px;
    }
  }

  .logo {
    margin-bottom: 20px;
  }

  .action {
    margin-top: 12px;

    button {
      height: 44px;
    }
  }

  .subtitle {
    margin-bottom: 8px;
  }
}

.error {
  color: coral;
  text-decoration: underline;
}