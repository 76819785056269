.depositLeftBox {
  h4 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.tokenSelect {
  margin-bottom: 16px;
}

.subtitle {
  max-width: 310px;
  font-size: 12px;
  line-height: 13px;
  opacity: 0.5;
  margin-bottom: 20px;
}

.subtitleChange {
  margin-bottom: 20px;
}

.buyBnt {
  margin: 16px 0 40px;

  button {
    padding: 14px;
    border-radius: 8px !important;
    display: flex;
    column-gap: 10px;
    align-items: center;
  }
}

.networkActions {
  display: flex;
  align-items: center;
  column-gap: 16px;
  height: 50px;
  margin: 20px 0;

  button {
    border-radius: 8px !important;
    height: 100%;
  }

  button:disabled {
    opacity: .5;
  }

  &__show {
    padding: 18px;

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 14px !important;
    }
  }

  &__scan {
    padding: 13px;
  }
}

.wrapper {
  margin-top: 20px;

  & > div > div {
    p {
      opacity: 1 !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .buyBnt {
    margin: 20px 0 32px;

    button {
      width: 100%;
      height: 52px;
      justify-content: center;
    }
  }

  .networkActions {
    column-gap: 16px;
    height: 50px;
    margin: 20px 0 20px;

    &__show {
      padding: 18px;
      width: 100%;
    }
  }
}