.wrapper {
  height: 100%;
  overflow: auto;

  & > div {
    //height: 100%;
  }
}

.auth {
  overflow-x: hidden;
}

.app {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 45px 82px 40px;
  overflow: hidden;
  min-height: 100vh;
}

.logoFooter {
  margin-bottom: 0;
  padding: 0 0 0;
  position: relative;
  z-index: 1;

  img {
    object-fit: contain;
    width: 120%;
    margin-left: -10%;
  }
}

@media only screen and (max-width: 767px) {
  .app {
    padding: 27px 16px 40px;
  }
}

//.box {
//width: 900px;
//height: 700px;
//}

.testChanger {
  position: fixed;
  bottom: 105px;
  right: 50px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    font-size: 12px;
    text-shadow: 1px 1px 2px #000;
  }
}

.switcherBox, .switcherBoxActive {
  width: 32px;
  height: 20px;
  border-radius: 50px;
  background-color: rgba(120, 120, 128, 0.3607843137);
  position: relative;
  transition: all .2s;
}

.switcherFalse, .switcherTrue {
  width: 17px;
  height: 17px;
  background-color: #fff;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 3px 1px 0px #0000000F;
  box-shadow: 0px 3px 8px 0px #00000026;
  left: 1px;
  top: 1px;
  transition: all .2s;
}

.switcherTrue {
  left: calc(100% - 19px);
}