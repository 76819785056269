.fieldSelect, .fieldSelectLight {
  border: 1px solid #FFFFFF80;
  border-radius: 11px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.fieldBox, .fieldBoxWrong {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 9px;
  margin-left: 8px;
  width: 100%;

  label {
    font-size: 14px;
    line-height: 15px;
    opacity: 0.6;
    color: #FFFAFA;
    padding-top: 2px;
  }

  input {
    height: 100%;
    width: 100%;
  }
}

.fieldBoxWrong {
  border: 1px solid rgba(255, 0, 0, 0.7);
}

.field {
  border: transparent;
  background-color: transparent;
}

.select {

}

.option {
  display: flex;
  align-items: center;
  column-gap: 8px;

  img {
    width: 26px;
    height: 26px;
  }

  span {
    margin-top: 2px;
  }
}

.fieldSelectLight {
  border: 1px solid #C9CEDE;
}

@media only screen and (max-width: 743px) {
  .fieldSelect, .fieldSelectLight {
    padding: 4px;
  }

  .fieldBox, .fieldBoxWrong {
    row-gap: 6px;
    margin-left: 0;
  }
}