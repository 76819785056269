.notificationsPage {
  margin-top: 8px;
  padding: 0 40px;
  margin-bottom: 60px;
  position: relative;

  h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 28px;
    max-width: 1150px;
    margin: 0 auto 25px;
  }
}

.notificationsWrapper {
  display: flex;
  column-gap: 20px;
  justify-content: center;
}

@media only screen and (max-width: 743px) {
  .notificationsPage {
    padding: 0 16px;
    margin-top: 24px;

    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 17px;
      text-align: center;
    }
  }

  .notificationsWrapper {
    flex-direction: column;
    row-gap: 22px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 32px;

    h2 {
      margin: 0;
    }
  }
}