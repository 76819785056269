.stakingLeftBox {
  h4 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.subtitle {
  max-width: 310px;
  font-size: 12px;
  line-height: 13px;
  opacity: 0.5;
  margin-bottom: 20px;
}

.space {
  display: block;
  margin-bottom: 20px;
}

.action {
  margin: 20px 0;

  button {
    width: 100%;
    height: 50px;
    border-radius: 8px !important;

    span {
      font-size: 16px;
      line-height: 14px;
    }
  }
}

.rightBox {
  margin: 32px 0;
  background-color: #171E33;
  border-radius: 20px;
  padding: 16px;
}

.wrapper {
  margin: 20px 0 -10px;

  & > div > div {
    p {
      opacity: 1 !important;
    }
  }
}

@media only screen and (max-width: 743px) {
  .rightBox {
    margin: 14px 0;
    padding: 12px;
  }
}