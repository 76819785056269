.wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  &:before {
    top: 0;
    left: -100px;
    height: 100%;
    width: calc(100vh + 50000px);
    background-color: #0A0C24;
  }
}
.home {
  //padding: 32px 0 96px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

@media only screen and (max-width: 1920px) {
  .wrapper {
    max-width: 1600px;
  }
}