.event, .eventHide {
  position: fixed;
  min-width: 320px;
  max-width: 440px;
  min-height: 100px;
  height: auto;
  right: 50px;
  top: 50px;
  padding: 24px 16px;
  border-radius: 8px;
  transition: all .15s;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  p {
    word-wrap: break-word;
    font-size: 18px;
    font-weight: 500;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    p:first-child {
      font-weight: 600;
    }

    p:last-child {
      font-weight: 300;
      line-height: 16px;
      font-size: 14px;
    }
  }
}

.eventHide {
  right: -500px;

}