.toggleContent {
  display: flex;
  align-items: center;
  column-gap: 8px;

  &__toggle {
    //max-width: 227px;
    width: 100%;

    p {
      font-size: 12px;
      line-height: 11px;
    }
  }
}

.information {
  margin-top: 32px;

  > div {
    border-radius: 8px;
  }

  h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 16px;
  }

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 40px;

    span {
      font-size: 12px;
      line-height: 13px;
      font-weight: 500;
      opacity: 0.5;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .toggleContent {
    &__toggle {
      max-width: 100%;
    }
  }
}