.set2FAAuth {
  h2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
    column-gap: 8px;


    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 13px;
      background-color: #EDF3FF;
      color: #485A8E;
      padding: 5px 8px;
      border-radius: 14px;
    }
  }
}

.info {
  display: flex;
  align-items: flex-start;
  column-gap: 80px;

  &__notifications {
    max-width: 483px;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  max-width: 380px;
}

.stepBox {
  display: flex;
  column-gap: 30px;

  &__index {
    width: 58px;
    height: 62px;
    min-width: 58px;
    min-height: 62px;
    background-color: #2C4A7E;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 28px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: -0.03em;
    }
  }

  &__text {
    p {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 6px;
    }

    span {
      font-size: 16px;
      line-height: 18px;
      opacity: 0.3;
    }
  }
}

.downloadLink {
  text-decoration: underline;
  display: block;
}

.qrCode {
  &__qrImage {
    margin-bottom: 13px;
    width: 282px;
    height: 282px;
    border-radius: 10px;
  }

  p {
    padding: 16px 16px;
    border-radius: 11px;
    font-size: 12px;
    font-weight: 500;
  }
}

.fieldCode, .fieldCodeShow, .fieldCodeHide {
  padding: 16px;
  background: #171E33;
  max-width: 430px;
  margin: 30px 0 14px;
  border-radius: 18px;

  &__title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  input {
    margin: 0 !important;
    border: 1px solid #2C4A7E !important;
    border-radius: 16px !important;
    background-color: transparent !important;

    width: 60px !important;
    height: 62px !important;
    padding-left: 19px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  > div {
    display: flex !important;
    column-gap: 6px;
  }
}

.notificationCode {
  margin: 15px 0;
}

.fieldCodeShow, .fieldCodeHide {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  background-color: #0C1223;
  margin: 0;
  padding: 38px 90px 100px;
  z-index: 1;

  input {
    margin: 0 !important;
    border: 1px solid #2C4A7E !important;
    border-radius: 8px !important;
    background-color: transparent !important;
    color: #fff !important;
    width: 100% !important;
    max-width: 70px !important;
    min-width: 53px !important;
    height: 72px !important;
    padding-left: 28px !important;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: -0.03em !important;
  }

  > div {
    display: flex !important;
    column-gap: 6px;
    justify-content: space-between;
  }

  &__title {
    font-size: 24px;
    line-height: 21px;
    font-weight: 700;
    margin-bottom: 36px;
    letter-spacing: -0.02em;
    text-align: center;
  }
}

.fieldCodeHide {
  left: -300vw;
}

.action {
  margin-top: 14px;
  max-width: 483px;

  button:disabled {
    opacity: 0.4;
  }

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.03em;
  }
}

@media only screen and (max-width: 1410px) {
  .info {
    justify-content: space-between;
    column-gap: 32px;
  }
}

@media only screen and (max-width: 1280px) {
  .info {
    max-width: 380px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    row-gap: 52px;
  }

  .steps {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    max-width: 380px;
  }
}



@media only screen and (max-width: 1024px) {
  .fieldCodeShow {
    padding: 38px 16px 100px;
  }

  .set2FAAuth {
    padding-bottom: 132px;
  }

  .info {
    display: flex;
    max-width: 100%;
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .codeHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;

    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 17px;
      margin-bottom: 0;
    }

    span {
      opacity: 0;
    }
  }

  .notificationCode p {
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
  }

  .downloadLink {
    text-decoration: underline;
    color: #1363FF !important;
    opacity: 1 !important;
  }

  .qrCode {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 52px;

    &__qr {
      object-fit: contain;
      width: 185px;
      height: 185px;
    }

    p {
      padding: 18px;
      border: 1px solid transparent;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #1B263B;
      width: 100%;
      border-radius: 18px;

      button {
        width: 34px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2359B8;
        border-radius: 6px;

        img {

        }
      }
    }
  }


  .action {
    margin-top: 52px;
    max-width: 100%;
  }

  .steps {
    row-gap: 32px;
    max-width: 300px;
  }

  .stepBox {
    column-gap: 18px;

    &__index {
      width: 48px;
      height: 51px;
      min-width: 48px;
      min-height: 51px;
      background-color: #2C4A7E;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 20px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.03em;
      }
    }
  }
}

@media only screen and (max-width: 743px) {
  .qrCode {
    margin-bottom: 0;

    p {
      padding: 8px;
    }
  }

  .info {
    row-gap: 24px;
  }
  .stepBox {
    &__index {
      width: 48px;
      height: 51px;
      min-width: 48px;
      min-height: 51px;
    }
  }
}