.historyListMob {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 20px;
}

.historyItemMob {
  border-radius: 18px;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;

  &__coin {
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;

    span {
      opacity: 0.3;
    }
  }

  &__date {
    font-size: 12px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: -0.03em;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-top: 14px;

  div {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 11px;
      letter-spacing: -0.03em;
      opacity: 0.5;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 11px;
      letter-spacing: -0.03em;
    }
  }
}

.showMore {
  display: flex;
  justify-content: center;

  &__button {
    padding: 0 8px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
  }
}