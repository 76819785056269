.littleChanger {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  border-radius: 8px;
  align-items: center;

  p {
    font-size: 12px;
    line-height: 10px;
    width: 43px;
    text-align: center;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41px;
    height: 38px;
    border-radius: 8px;

    span {
      font-size: 12px;
      line-height: 10px;
    }
  }
}