.headerWrapper {
  padding: 14px 90px 32px;
  background-size: 110% 200%;
  background-repeat: no-repeat;
  background-position: 50% 10px;
}

.imgLogo {
  height: 17px;
}

.logo {
  font-size: 24px;
  font-weight: 900;
  font-family: "Audiowide", serif;
  display: block;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //height: 43px;
  //padding: 0 82px 140px;

  &__menuBox {
    display: flex;
    column-gap: 30px;
    align-items: center;
  }

  &__menuBoxMob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 50px;
  }
}

.navigation {
  display: flex;
  align-items: center;
  column-gap: 14px;

  &__link, &__linkActive {
    line-height: 17px;
    position: relative;
    font-size: 14px;

    &:before {
      position: absolute;
      content: '';
      bottom: -10px;
      left: 50%;
      width: 0;
      height: 3px;
      border-radius: 3px;
      background-color: transparent;
      transition: all .3s;
    }

    &:after {
      position: absolute;
      content: '';
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 3px;
      border-radius: 3px;
      background-color: transparent;
      transition: all .2s;
    }
  }

  &__linkActive:after {
    background-color: #2451D1;
  }

  &__link:hover, &__linkActive:hover {
    &:before {
      width: 100%;
      left: 0;
      background-color: #2451D1;
    }
  }
}

.login {
  padding: 5px;
}

.signUp {
  padding: 12px 26px;
  background-color: #33436D;
  border-radius: 10px;
}

.subHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  column-gap: 64px;
}

@media only screen and (max-width: 1024px) {
  .headerWrapper {
    padding: 24px 40px 24px;
    background-size: 140% 225%;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    position: relative;
  }
}

@media only screen and (max-width: 743px) {
  .headerWrapper {
    padding: 24px 18px 10px;
    background-size: 180% 225%;
  }

  .header {
    &__menuBoxMob {
      padding: 0;
    }
  }

  .mobileNavigation {
    margin: 16px 0 0 16px;
  }

  .headerTitle {
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
    padding: 24px 0 24px;
  }

  //.subHeader {
  //  margin-right: -18px;
  //}
}