.spot .MuiInputBase-root {
  p, span {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding-top: 1px;
  }
}


body:has(.spot) .MuiList-root.MuiList-padding.MuiMenu-list {
  padding: 4px 0;
  border: none;

  li {
    height: 38px;
    padding: 0 0 0 12px;
    max-height: 38px;
    min-height: 38px;
  }
}

body:has(.spot.dark) .MuiPaper-root.MuiPaper-elevation {
  border: none !important;
  background-color: #201D2B !important;
  margin-top: 2px;
}

.spot .MuiInputBase-root svg {
  position: absolute;
  right: 9px;
  pointer-events: none;
}

.spot #demo-simple-select {
  height: 100%;
  padding: 0 0 0 12px;
  border: none;
}