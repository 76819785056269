.depositQuestions {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

}

.question, .questionActive {
  border-radius: 11px;

  h4 {
    cursor: pointer;
    padding: 20px 54px 20px 20px;
    font-weight: 500;
    line-height: 19px;
    transition: .15s;
    position: relative;

    img {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%) rotateZ(180deg);
      transition: all .15s;
    }
  }

  p {
    height: 1px;
    max-height: 1px;
    font-size: 14px;
    line-height: 20px;
    opacity: 0;
    pointer-events: none;
    padding: 0 20px 0;
    transition: all .15s;
  }
}


.questionActive {
  h4 {
    padding: 20px 54px 10px 20px;

    img {
      transform: translateY(-50%) rotateZ(0deg);
    }
  }

  p {
    height: 100%;
    max-height: 1000px;
    opacity: 0.5;
    padding: 0 20px 20px;
  }
}