.setLanguage {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  h2 {
    font-size: 40px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 24px;
  }
}

.language, .languageActive {
  pointer-events: all;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 360px;
  border-radius: 18px;
  cursor: pointer;

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.03em;
  }


  span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}

.languageActive {
  pointer-events: none;


}