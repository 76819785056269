.getStarted {
  position: relative;
  padding-bottom: 53vw;

  img {
    top: 150px;
    position: absolute;
    height: 45vw;
    width: calc(100% + 4px);
    pointer-events: none;
    left: 50%;
    object-fit: contain;
    transform: translateX(-50%);
  }

  &__info {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }

  &__subTitle {
    max-width: 492px;
    text-align: center;
    margin: 0 auto 40px;
    line-height: 0.9em;
    font-size: 40px;
  }

  &__action {
    margin: 0 auto;
    background-color: #2359B8;
    border-radius: 10px;
    padding: 27px 46px;
    display: block;


    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .getStarted {
    padding-bottom: 52vw;

    //img {
    //  height: 900px;
    //  width: calc(100% + 162px);
    //  left: 51%;
    //  transform: translateX(-50%);
    //}
  }
}

@media only screen and (max-width: 1280px) {
  .getStarted {
    &__subTitle {
      max-width: 492px;
      text-align: center;
      margin: 0 auto 40px;
      line-height: 0.9em;
      font-size: 30px;
    }

    &__action {
      margin: 0 auto;
      background-color: #2359B8;
      border-radius: 10px;
      padding: 20px 30px;
      display: block;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .getStarted {
    padding-bottom: 94vw;


  img {
      top: 110px;
      position: absolute;
      height: 72vw;
      width: 100vw;
      pointer-events: none;
      left: 50%;
      object-fit: contain;
      transform: translateX(-50%);
    }

    &__subTitle {
      max-width: 390px;
      margin: 0 auto 32px;
      font-size: 24px;
    }

    &__action {
      padding: 20px 24px;
      display: flex;
      height: 57px;
      justify-content: center;
      align-items: center;

      span {
        font-size: 15px;
      }
    }

    &__info {
      bottom: 70px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .getStarted {
    padding-bottom: 132vw;

    img {
      height: 120vw;
    }

    &__subTitle {
      width: 222px;
      margin: 0 auto 26px;
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .getStarted {
    padding-bottom: 146vw;
  }
}

@media only screen and (max-width: 500px) {
  .getStarted {
    padding-bottom: 152vw;
  }
}
