.footerNavigation {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  justify-content: center;
  padding-top: 17px;
  column-gap: 17px;
  z-index: 50;
}

.linksBox {
  display: flex;
  column-gap: 23px;
  align-items: center;
  height: 41px;
}

.link {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  align-items: center;
  width: 66px;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 12px;
    line-height: 14px;
    color: #7296D5;
  }
}

@media only screen and (max-width: 620px) {
  .footerNavigation {
    justify-content: space-between;
    padding: 17px 14px 0;
  }
}