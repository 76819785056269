.graySelect {
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    background-color: #333F61;
    border-radius: 8px;
    padding: 13px 14px;
    border: 1px solid transparent;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
    border: 1px solid transparent;
    border-radius: 8px;
  }
}

.light .fieldSelect {
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    background-color: #485A8E;
    border-radius: 8px;
    padding: 13px 14px;
    border: 1px solid transparent;

    p, span {
      color: #fff;
    }
  }
}

body:has(.light .fieldSelect) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
    border: 1px solid #FFFFFF80;
    border-radius: 8px;
    background-color: #485A8E;
    color: #fff;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #333F61;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #2359B8;
  }
}

body:has(.dark .fieldSelect) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
    border: 1px solid transparent;
    border-radius: 8px;

    background-color: #333F61;
    color: #fff;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #485A8E;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #2359B8;
  }
}
body:has(.light .fieldSelectLight) .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root p span {
  color: #fff !important;
}

@media only screen and (max-width: 743px) {
  .light .fieldSelect {
    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
      background-color: #485A8E;
      border-radius: 8px;
      padding: 8px;
      border: 1px solid transparent;

      p, span {
        color: #fff;
      }
    }
  }
}