.spotHeader {
  padding: 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.decor {
  height: 32px;
  width: 1px;
  background-color: rgba(217, 217, 217, 0.3);
}

@media only screen and (max-width: 743px) {
  .spotHeader {
    flex-direction: column;
    row-gap: 12px;
    align-items: flex-start;
  }
}