.followNews .swiper-button-next, .followNews .swiper-button-prev {
  color: #606060;
  width: 44px;
  height: 44px;
  padding: 10px;
  border: 1px solid #212121;
  border-radius: 10px;
  top: 16px;
  margin-top: 0;
}

.followNews .swiper-button-next {
  right: 81px;
}

.followNews .swiper-button-prev {
  right: 138px;
  left: auto;
}

.followNews .swiper-button-prev:after, .followNews .swiper-button-next:after {
  font-size: 16px !important;
  font-weight: 800;
}

.followNews .swiper-wrapper {
  margin-right: -40px;
}