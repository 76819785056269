.popupQR {
  position: relative;

  &__close {
    position: absolute;
    right: 0;
    top: 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  &__qrCode {
    width: 276px;
    height: 276px;
    margin: 32px auto;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 276px;
      height: 276px;
    }
  }
}

.addressBox {
  padding: 18px;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  border-radius: 18px;

  button {
    width: 51px;
    height: 51px;
    min-height: 51px;
    min-width: 51px;
    background-color: #132240;
    border-radius: 8px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &__text {
    max-width: 80%;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 24px;
  }

  span {
    opacity: .5;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.03em;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.03em;
  }
}

@media only screen and (max-width: 1024px) {
  .popupQR {
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 64px 110px 96px;
    border-radius: 18px;

    &__close {
      right: 23px;
      top: 23px;
    }
  }

  .addressBox {
    min-width: 342px;
  }
}

@media only screen and (max-width: 743px) {
  .popupQR {
    padding: 64px 24px 24px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 150px);
    transform: none;
    top: inherit;
    z-index: 51;

    &__close {
      right: 23px;
      top: 16px;
    }
  }
}
