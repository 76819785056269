.transferLeftBox {
  h4 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.subtitle {
  max-width: 310px;
  font-size: 12px;
  line-height: 13px;
  opacity: 0.5;
  margin-bottom: 20px;
}

.secondTitle {
  margin-top: 20px;
}

.idField {
  height: 50px;
  width: 100%;
  padding-left: 20px;
}

.action {
  margin: 20px 0;

  button {
    width: 100%;
    height: 50px;

    span {
      font-size: 16px;
      line-height: 14px;
    }
  }
}

.wrapper {
  margin: 20px 0 -10px;

  & > div > div {
    p {
      opacity: 1 !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .secondTitle {
    margin-top: 16px;
  }

  .action {
    margin: 20px 0 20px;
  }
}

