.leverageWrapper {
  position: fixed;
  content: '';
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(4, 8, 27, 0.77);
  z-index: 1;
}

.leveragePopup {
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #191424;
  border-radius: 16px;

  h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    margin-bottom: 14px;
  }
}

.subTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  opacity: .5;
  margin-bottom: 24px;
}

.leverageField {
  height: 73px;
  width: 100%;
  background-color: #201D2B;
  border-radius: 16px;
  color: #fff;
  font-size: 34px;
  font-weight: 500;
  text-align: center;
}

.maxPositions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;

  p {
    color: #8A888F;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }
}

.leverageActions {
  display: flex;
  align-items: center;
  column-gap: 16px;

  button {
    width: 50%;
    height: 41px;
    border-radius: 8px !important;

    span {
      font-size: 11px;
      font-weight: 400;
      line-height: 13px;
    }
  }
}

.submit {

}

.cancel {
  border: 1px solid #2359B8;
}

@media only screen and (max-width: 743px) {
  .leveragePopup {
    padding: 20px;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #191424;
    border-radius: 16px;

    h4 {
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      margin-bottom: 14px;
    }
  }
}