.priceInputBox {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 8px 12px;
  border-radius: 8px;

  label {
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    opacity: .4;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    opacity: .4;
  }

  &__field {
    display: flex;
    justify-content: space-between;
  }

  input {
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
}

.boxHide {
  opacity: 0.5;
  cursor: inherit;
  pointer-events: none;
}