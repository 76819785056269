.table {
  padding: 62px 56px;
  background-color: #081122;
  border-radius: 40px;
  z-index: 3;
  position: relative;
}

.header {
  display: grid;
  grid-template-columns: 5fr 5fr 4fr 2fr;
  grid-column-gap: 20px;
  margin-bottom: 24px;

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    opacity: 0.3;
    text-transform: uppercase;
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  &__row {
    display: grid;
    grid-template-columns: 5fr 5fr 4fr 2fr;
    grid-column-gap: 20px;

    img {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
      display: flex;
      align-items: center;
    }

    span {
      opacity: 0.3;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
      margin-left: 10px;
    }
  }

  &__point {
    display: flex;
    align-items: center;

    a {
      height: 41px;
      width: 81px;
      background-color: #33436D;
      display: flex;
      align-items: center;
      border-radius: 11px;
      justify-content: center;

      span {
        opacity: 1;
        margin-left: 0;
        font-size: 15px;
        font-weight: 700;
        line-height: 16px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .table {
    padding: 60px 24px 60px 40px;
  }

  .content {
    row-gap: 0;

    &__row {
      grid-template-columns: 5fr 3fr 3fr 2fr;
      height: 82px;
      border-top: 1px solid rgba(168, 168, 191, 0.3);

      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 21px;
      }
    }

    &__point {
      button {
        height: 34px;
      }
    }
  }

  .header {
    grid-template-columns: 5fr 3fr 3fr 2fr;

    span {
      font-size: 11px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0.09em;
    }
  }
}

@media only screen and (max-width: 767px) {
  .table {
    padding: 16px;
    border-radius: 12px;
  }

  .content {
    row-gap: 0;

    &__row {
      display: flex;
      height: auto;
      flex-direction: column;
      padding-bottom: 20px;
      border-top: none;
      border-bottom: 1px solid rgba(168, 168, 191, 0.3);
      margin-top: 4px;
    }

    &__row:last-child {
      border-bottom: none;
    }

    &__row:first-child {
      margin-top: 0;
    }
  }

  .tokenName {
    padding: 16px 0;
    margin-bottom: 12px;
  }

  .tokenPrice {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  .tokenActions {
    button {
      width: 100%;
    }
  }

  span.content__label {
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.09em;
    color: #8D8DA6;
    margin-left: 0;
  }
}

