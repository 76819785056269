.footerMenuBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 17px;
  width: 98px;
  min-width: 98px;
  height: 41px;
  border-radius: 16px;
  background-color: #15234B;

  span {
    font-size: 12px;
    line-height: 14px;
    color: #7296D5;
  }
}

.footerMenu, .footerMenuActive {
  padding: 24px 32px;
  position: fixed;
  bottom: -130%;
  left: 0;
  width: 100%;
  z-index: 10;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  height: 332px;
  transition: all .15s;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.02em;
    }

    button {
      margin-right: 8px;
    }

    button img {
      width: 12px;
      height: 12px;
    }
  }
}

.footerMenuActive {
  bottom: 0;
}

@media only screen and (max-width: 743px) {
  .footerMenu, .footerMenuActive {
    padding: 24px 16px;
  }
}