.staking {
  margin-top: 32px;
  padding: 0 65px 240px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  &__leftBox {
    max-width: 500px;
    width: 100%;
    padding: 22px 16px;
    background-color: #171E33;
    border-radius: 20px;
  }

  &__rightBox {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &__rightTopBox {
    padding: 22px 16px;
    background-color: #171E33;
    border-radius: 20px;
    max-width: 500px;
  }

  &__rightBottomBox {
    padding: 22px 16px;
    border-radius: 20px;
    height: 100%;
    max-width: 500px;

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      padding-left: 20px;

      li {
        list-style: disc;
      }
    }
  }
}

.mainRow {
  display: flex;
  justify-content: center;
  column-gap: 18px;
}

.orders {
  max-width: 1018px;
  margin: 0 auto;
  width: 100%;
  border-radius: 20px;
  padding: 22px 16px;
}

.stakingValues {
  &__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 35px;
    margin-bottom: 14px;
  }

  &__subTitle {
    line-height: 18px;
    opacity: 0.5;
    margin-bottom: 52px;
  }
}

.valuesDetail {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__label {
    opacity: 0.5;
    font-size: 20px;
    line-height: 22px;
  }

  &__value {
    min-width: 175px;
    max-width: 175px;
    width: 100%;

    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 26px;
      background: radial-gradient(101.92% 131.5% at 80.77% 88.46%, #072C96 0%, #597DE4 100%);
      -webkit-text-fill-color: rgba(0, 0, 0, 0);
      -webkit-background-clip: text;
      margin-bottom: 14px;
    }

    span {
      font-size: 14px;
      line-height: 15px;
      background: radial-gradient(101.92% 131.5% at 80.77% 88.46%, #072C96 0%, #597DE4 100%);
      -webkit-text-fill-color: rgba(0, 0, 0, 0);
      -webkit-background-clip: text;
    }
  }
}

.valuesDecor {
  margin: 24px 0;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .staking {
    padding: 0 90px 140px;
    flex-direction: column;
    row-gap: 20px;

    &__leftBox {
      max-width: 100%;
      background-color: transparent !important;
      padding: 0;
    }

    h3 {
      font-size: 40px;
      line-height: 44px;
    }
  }

  .stakingValues {
    &__title {
      font-size: 20px;
      line-height: 22px;
    }

    &__subTitle {
      line-height: 18px;
      font-size: 14px;
      opacity: 0.5;
      margin-bottom: 52px;
    }
  }

  .valuesDetail {
    &__label {
      font-size: 16px;
      line-height: 17px;
    }

    &__value {
      p {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 743px) {
  .staking {
    padding: 0 16px 84px;
  }

  .stakingValues {

    &__subTitle {
      margin-bottom: 12px;
    }
  }

  .valuesDecor {
    margin: 8px 0;
  }

  .valuesDetail {
    &__value {
      p {
        margin-bottom: 6px;
      }
    }
  }
}
