.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  border-radius: 11px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.dark {
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    border: 1px solid #FFFFFF80;
  }
}

.light {
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    border: 1px solid #C9CEDE;
    color: #333F61;
  }
}

body:has(.dark) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
    border: 1px solid #FFFFFF80;
    border-radius: 11px;
    background-color: #171E33;
    color: #fff;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #333F61;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #2359B8;
  }
}
