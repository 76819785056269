.wallets {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow-y: auto;
  max-height: 775px;
}

.wallet {
  padding: 14px;
  border-radius: 14px;
  background-color: #303953;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__headerLeft {
    display: flex;
    align-items: center;
    column-gap: 12px;

    img {
      width: 30px;
      height: 30px;
    }

    p {
      font-size: 14px;
      line-height: 12px;
      letter-spacing: -0.03em;
    }
  }

  &__valuesList {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 12px;
        line-height: 11px;
        letter-spacing: -0.03em;
      }
    }
  }
}