.notificationTabs {
  padding: 20px 40px 20px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.tab, .tabActive {
  display: flex;
  align-items: center;
  column-gap: 16px;

  &__icon {
    display: flex;
    height: 28px;
    width: 28px;
    background-color: #465683;
    border-radius: 9px;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    opacity: .6;
    transition: all 0.15s;
    cursor: pointer;
    white-space: nowrap;
  }
}

.tabActive {
  p {
    opacity: 1;
  }
}

@media only screen and (max-width: 743px) {
  .notificationTabs {
    background-color: transparent !important;
    padding: 0 0 10px 0;
    flex-direction: row;
    column-gap: 8px;
    border-radius: 0;
    overflow-x: auto;

    > * {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    }
  }

  .tab, .tabActive {
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding: 6px 12px;
    border-radius: 10px;

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

