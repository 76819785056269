.orderBook {
  //position: relative;

  &__select {
    padding: 0 12px 12px;
    display: flex;
    justify-content: flex-end;
  }
}

.formSkeleton {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  border-radius: 16px;
  display: block;
  height: 100%;
}

.comparison {
  margin-top: 8px;
  padding: 0 12px;
  width: 100%;
  display: flex;

  &__left, &__right {
    height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    column-gap: 6px;

    p {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p, span {
      font-size: 11px;
      font-weight: 400;
      line-height: 13px;
    }
  }

  &__left {
    background-color: #1D3C38;

    p {
      border: 1px solid #289587;
    }
  }

  &__right {
    background-color: #CF234A4D;
    justify-content: flex-end;

    p {
      border: 1px solid #941F1F;
    }
  }
}

@media only screen and (max-width: 743px) {
  .comparison {
    padding: 0;
    margin-bottom: 10px;
  }
}