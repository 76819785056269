.connectWalletForm {
  h4 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.name {
  margin-top: 20px;
}

.subtitle {
  max-width: 310px;
  font-size: 12px;
  line-height: 13px;
  opacity: 0.5;
}

.subtitleChange {
  margin-bottom: 20px;
}

.field {
  position: relative;

  span {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    padding: 14px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 10px;
    background-color: #1C243D;
  }
}

.connectField, .connectFieldSeed {
  height: 50px;
  width: 100%;
  margin: 20px 0;
  padding-left: 80px;
}

.connectFieldSeed {
  padding-left: 116px;
}

.notification {
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  margin-top: 24px;

  span {
    opacity: 0.5;
    font-size: 12px;
    line-height: 13px;
  }
}

.action {
  margin-bottom: 24px;

  button {
    width: 100%;
    height: 50px;

    span {
      font-size: 16px;
      line-height: 14px;
    }
  }

  button:disabled {
    opacity: .3;
  }
}

@media only screen and (max-width: 1024px) {
  .connectField, .connectFieldSeed {
    margin: 20px 0 20px;
  }
  .field {
    span {
      top: 50%;
    }
  }
}


@media only screen and (max-width: 743px) {
  .subtitleChange {
    margin-bottom: 16px;
  }

  .name {
    margin-top: 16px;
  }
}