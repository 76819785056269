.tokenChanger {
  //margin-top: 20px;
}

.tokenChangerButtons {
  margin-top: 16px;
  display: flex;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
  overflow-x: auto;

  &__toggle {
    width: auto;

    span {
      font-size: 12px;
      line-height: 10px;
      padding: 14px;
      display: block;
      width: 100%;
      height: 100%;
    }

    p {
      font-size: 12px;
      line-height: 10px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.option {
  display: flex;
  align-items: center;
  column-gap: 8px;

  span {
    margin-top: 4px;
  }

  &__token {
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    opacity: .3;
  }

  img {
    width: 26px;
    height: 26px;
  }
}