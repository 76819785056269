.setPassword {
  max-width: 743px;

  &__header {
    max-width: 531px;
    width: 100%;

    h2 {
      font-size: 40px;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 16px;
    }
  }

  &__subTitle {
    font-size: 18px;
    line-height: 20px;
    opacity: 0.5;
    margin-bottom: 24px;
    max-width: 400px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 60px;

  input {
    height: 50px;
    padding-left: 20px;
  }

  &__error {
    color: #F43443 !important;
    text-align: center;
  }
}

.action {
  width: 100%;

  button {
    width: 100%;
    height: 50px;

    &:disabled {
      opacity: 0.4;
    }

    span {
      font-size: 18px;
      line-height: 16px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .setPassword {
    max-width: 100%;

    &__header {
      h2 {
        font-size: 32px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 20px;
      }
    }

    &__subTitle {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 32px;
      max-width: 240px;
    }
  }

  .form {
    margin-bottom: 40px;
  }
}


