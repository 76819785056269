.historyList {
  &__labels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #A8A8BF;

    p {
      opacity: 0.5;
      font-size: 11px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 20px;
    padding-bottom: 18px;
    border-bottom: 1px solid#A8A8BF;

    p {
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
    }
  }

  &__token {
    display: flex;
    column-gap: 8px;
    align-items: center;

    img {
      width: 26px;
      height: 26px;
    }

    span {
      opacity: 0.3;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}

.list {
  max-height: 705px;
  height: 100%;
  overflow: auto;
}

@media only screen and (max-width: 1280px) {
  .historyList {
    &__tokenAmount {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__tokenField {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__items {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      margin-top: 16px;
      padding-bottom: 16px;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
}

@media only screen and (max-width: 743px) {
  .historyList {
    &__labels {
      overflow: scroll;
      p {
        min-width: 100px;
      }
    }
  }
}