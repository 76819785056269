.filtersWrapper .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
  padding: 10px 14px !important;
}

.filtersWrapper .Filters_option__BwvcJ span {
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
}

.filtersWrapper .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  background-color: transparent;
  border: 1px solid #33436D;
  border-radius: 12px;
  padding: 0;
  height: 45px;
  cursor: pointer;

  svg {
    pointer-events: none;
    cursor: pointer;
    margin-right: 10px;
    width: 24px;
  }
}

.filtersWrapper .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  border: none;
}

.MuiDateRangeCalendar-root.css-e47596-MuiDateRangeCalendar-root div:first-child {
 color: transparent !important;
}

.MuiPickersArrowSwitcher-root.css-1uunzz6-MuiPickersArrowSwitcher-root {
  p, span {
    color: rgba(0, 0, 0, 0.87);
  }
}

.filtersWrapper .MuiStack-root.css-nby9nv-MuiStack-root {
  padding-top: 0;
  max-height: 45px;
}

.filtersWrapper .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  padding-right: 0;
  max-height: 45px;
}

.filtersWrapper .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
  padding: 0 14px;
  height: 43px;
  border-radius: 12px;
}

body:has(.dark .filtersWrapper) .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
  border: 1px solid #33436D !important;
}

body:has(.light .filtersWrapper) .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
  border: 1px solid #33436D !important;
}


@media only screen and (max-width: 743px) {
  .filtersWrapper .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
    height: 40px;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.03em;
  }

  .MuiDateRangeCalendar-root.css-e47596-MuiDateRangeCalendar-root div:first-child {
    color: transparent !important;
  }
  .filtersWrapper .MuiPickersCalendarHeader-label.css-dplwbx-MuiPickersCalendarHeader-label {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .MuiPickersCalendarHeader-labelContainer.css-cyfsxc-MuiPickersCalendarHeader-labelContainer div:first-child {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}