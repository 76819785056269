.userInfo {
  display: flex;
  column-gap: 50px;
  max-width: 49%;
  //width: 100%;

  &__user {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    p {
      white-space: nowrap;
      height: 28px;
      font-size: 16px;
      line-height: 14px;
      opacity: 0.5;
    }
  }
}

.authBoxWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 32px;
}

.authBox {
  display: flex;
  align-items: center;
  column-gap: 16px;

  span  {
    font-size: 28px;
    line-height: 30px;
    font-weight: 500;
  }
}

.usePhoto {
  width: 34px;
  min-width: 34px;
  height: 34px;
  border-radius: 12px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {

  & > div {
    margin-bottom: 0;
  }

  & > div > div {

    p {
      opacity: 1 !important;
    }
  }
}

@media only screen and (max-width: 1360px) {
  .userInfo {
    max-width: 36%;
  }
}


@media only screen and (max-width: 1024px) {
  .userInfo {
    margin: 0 50px;
    width: 100%;
    max-width: 100%;

    &__user {
      max-width: 100%;
      row-gap: 14px;
    }

    &__data {
      display: flex;
      flex-direction: column;
      row-gap: 0;
      align-items: flex-start;

      p {
        display: flex;
        align-items: center;
        height: 22px;
        font-size: 14px;
        line-height: 12px;
        opacity: 0.5;
      }
    }
  }
}

@media only screen and (max-width: 743px) {
  .userInfo {
    margin: 0;
    width: 100%;

    &__user {
      row-gap: 14px;
    }

    &__data {
      row-gap: 4px;
    }
  }

  .authBox {
    span  {
      font-size: 32px;
      line-height: 28px;
    }
  }
}