.footerMenuNavigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 8px;

  a {
    height: 50px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 0 20px;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    img {
      width: 14px;
      height: 14px;
    }
  }
}