.stakingDays {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-top: 20px;
}

.day, .dayActive {
  display: flex;
  justify-content: space-between;
  padding: 18px;
  background-color: #1C243D;
  border-radius: 14px;
  cursor: pointer;
  border: 1px solid transparent;

  &__duration {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-size: 20px;
      line-height: 22px;
      white-space: nowrap;
    }

    span {
      opacity: 0.5;
      font-size: 14px;
      line-height: 15px;
      white-space: nowrap;
    }
  }

  &__perDay {
    display: flex;
    flex-direction: column;

    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 26px;
      background: radial-gradient(101.92% 131.5% at 80.77% 88.46%, #072C96 0%, #597DE4 100%);
      -webkit-text-fill-color: #0000;
      -webkit-background-clip: text;
      white-space: nowrap;
    }

    span {
      background: radial-gradient(101.92% 131.5% at 80.77% 88.46%, #072C96 0%, #597DE4 100%);
      -webkit-text-fill-color: #0000;
      -webkit-background-clip: text;
      font-size: 14px;
      line-height: 15px;
      white-space: nowrap;
    }
  }
}

.dayActive {
  border: 1px solid #2359B8;
}

@media only screen and (max-width: 1024px) {
  .stakingDays {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow-x: auto;
    margin-right: -90px;
    padding-right: 16px;
  }

  .day, .dayActive {
    padding: 16px 12px;
    height: 66px;
    width: 182px;

    &__duration {
      justify-content: center;
      p {
        font-size: 18px;
        line-height: 20px;
      }
    }

    &__perDay {
      p {
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
}

@media only screen and (max-width: 743px) {
  .stakingDays {
   padding-bottom: 10px;
  }
  .day, .dayActive {
    padding: 12px 12px;
    height: 66px;
    width: 182px;
  }
}