.spotHeaderData {
  display: flex;
  column-gap: 36px;
  align-items: center;
  padding: 0 18px;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    opacity: .5;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}

.balance {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  p {
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  span {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}

.dayChange {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  span {
    color: #0BB731;
  }
}

.dayBox {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.mobSpotHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mobRightBox {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  p {
    font-size: 8px;
    line-height: 10px;
    opacity: 0.5;

    span {
      padding-left: 8px;
    }
  }
}

.green {
  color: #0BB731 !important;
}

.red {
  color: #F43443 !important;
}
