.transfer {
  display: flex;
  justify-content: center;
  column-gap: 18px;
  margin: 32px 0 170px;
  padding: 0 65px;

  &__leftBox {
    max-width: 450px;
    width: 100%;
    padding: 22px 16px;
    background-color: #171E33;
    border-radius: 20px;
  }

  &__rightBox {
    max-width: 450px;
    width: 100%;
    padding: 22px 16px;
    background-color: #171E33;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    padding: 0 90px;
  }

  .transfer {
    margin-top: 0;
    margin-bottom: 210px;
    padding: 0;

    &__leftBox {
      max-width: 100%;
      padding: 0;
      background-color: transparent !important;
    }

    &__rightBox {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 743px) {
  .wrapper {
    padding: 16px 16px;
  }

  .transfer {
    margin-bottom: 32px;
  }
}
