.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //height: 43px;
  padding: 0 0 110px;
  position: relative;

  &__logo {
    //width: 193px;
    font-size: 24px;
    font-weight: 900;
    font-family: "Audiowide", serif;
  }

  &__menuBox {
    display: flex;
    column-gap: 94px;
    align-items: center;
  }

  &__authBox {
    display: flex;
    align-items: center;
    column-gap: 40px;
    width: 193px;

    button {
      margin-left: 7px;
      width: 40px;
      height: 37px;
      border-radius: 10px;
      background-color: #33436D;
    }
  }
}

.menu, .menuActive {
  position: absolute;
  top: 100%;
  left: -82px;
  height: 0;
  width: calc(100% + 164px);
  background-color: #0B0D25;
  z-index: 1;
  transition: all .2s;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 64px;

  a {
    font-size: 32px;
    line-height: 39px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;
  }
}

.menuActive {
  height: 668px;

  a {
    opacity: 1;
    pointer-events: all;
  }
}

.navigation {
  display: flex;
  align-items: center;
  column-gap: 50px;
}

.login {
  //padding: 5px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
}

.signUp {
  width: 107px;
  background-color: #2359B8;
  border-radius: 10px;
  padding: 12px 26px;
  height: 43px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  max-width: 107px;
}

@media only screen and (max-width: 1280px) {
  .header {
    padding: 0 0 88px;
  }

  .navigation {
    column-gap: 40px;
  }

  .header__menuBox {
    display: flex;
    column-gap: 60px;
  }

}

@media only screen and (max-width: 1024px) {
  .header {
    padding: 0 0 60px;

    &__logo {
      width: 100px;

      img {
        height: 21px;
      }
    }

    &__authBox {
      width: auto;
      column-gap: 20px;
    }
  }

  .header__menuBox {
    img {
      width: 115px;
      height: 19px;
    }
  }

  .navigation {
    display: none;
  }

  .login {
    font-size: 14px;
    line-height: 15px;
  }

  .signUp {
    background-color: #2359B8;
    border-radius: 10px;
    height: 37px;
    font-size: 14px;
    line-height: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    &__logo {
      width: 100px;

      img {
        height: 16px;
      }
    }

    &__authBox {
      column-gap: 12px;

      button {
        margin-left: 7px;
        width: 30px;
        height: 30px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .header__menuBox {
    img {
      width: 89px;
      height: 15px;
    }
  }

  .login {
    font-size: 13px;
    line-height: 15px;
  }

  .signUp {
    border-radius: 10px;
    height: 30px;
    font-size: 13px;
    line-height: 15px;
    width: 75px;
    padding: 8px 15px;
  }

  .menu, .menuActive {
    padding-top: 0;
    left: -82px;
    height: 0;
    width: calc(100% + 164px);
    background-color: #0B0D25;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 64px;
    pointer-events: none;

    a {
      font-size: 32px;
      line-height: 39px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s;
    }
  }

  .menuActive {
    pointer-events: all;
    padding-top: 50px;
    height: 100vh;
    z-index: 2;

    a {
      opacity: 1;
      pointer-events: all;
    }
  }
}
