.exchangeTrade {
  position: relative;

  &__header {
    padding: 0 126px 832px;

    span {
      font-size: 24px;
      opacity: 0.5;
      margin-bottom: 33px;
      display: block;
    }

    h3 {
      font-size: 65px;
      font-weight: 500;
      max-width: 536px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  &__contentBox {
    position: relative;
    z-index: 2;
    max-width: 654px;

    p {
      margin-bottom: 36px;
      font-size: 29px;
      font-weight: 500;
      line-height: 40px;
    }
  }

  &__actions {
    display: flex;
    column-gap: 20px;
    align-items: center;
  }
}

.decor {
  position: absolute;
  width: calc(115% + 540px);
  top: 190px;
  left: -350px;
  height: 962px;
  object-fit: cover;
  pointer-events: none;
}

.linkComp {
  width: 228px;
  height: 79px;

  span {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }
}

.linkmob {
  height: 79px;
  width: 188px;
  border: 1px solid #33436D;
  border-radius: 19px;

  span {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 1280px) {
  .exchangeTrade__header {
    padding: 0 126px 700px;

    span {
      font-size: 20px;
      line-height: 14px;
      margin-bottom: 16px;
    }

    h3 {
      font-size: 44px;
      font-weight: 500;
      max-width: 536px;
    }
  }

  .decor {
    position: absolute;
    width: calc(115% + 540px);
    top: 0;
    left: -350px;
    height: 962px;
    object-fit: cover;
    pointer-events: none;
  }
}

@media only screen and (max-width: 1024px) {
  .exchangeTrade__header {
    padding: 0 0 832px;

    span {
      font-size: 16px;
      line-height: 14px;
      margin-bottom: 16px;
    }

    h3 {
      font-size: 32px;
      line-height: 28px;
      max-width: 380px;
    }
  }
}