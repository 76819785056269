.spotOrderForm {
  max-width: 286px;
  min-width: 286px;
  width: 100%;
  border-radius: 16px;
  padding: 10px 8px;
  position: relative;
  height: 100%;
}

.spotHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__tabs {
    display: flex;
    align-items: center;
    column-gap: 8px;

    button {
      padding: 8px 12px;
      background-color: #201D2B;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 11px;
        font-weight: 400;
        line-height: 13px;
      }
    }

    button.active {
      background-color: #2359B8;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 10px;

    button {
      width: 20px;
      height: 20px;

      img {
        width: 15px;
      }
    }
  }
}

.balance {
  margin-top: 6px;
  padding: 12px;
  border-radius: 8px;

  &__infoWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      opacity: 0.4;
      font-size: 11px;
      font-weight: 400;
      line-height: 13.2px;
    }

    span {
      font-size: 11px;
      font-weight: 500;
      line-height: 13px;
    }
  }

  &__actions, &__actionsLight {
    display: flex;
    margin-top: 12px;
    column-gap: 6px;

    a, button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 30px;
      border-radius: 7px !important;
      background-color: #2359B8;

      span {
        font-size: 11px;
        font-weight: 400;
        line-height: 13px;
      }
    }
  }

  &__actionsLight {
    a, button {
      background-color: #3866B7;

      span {
        color: #fff !important;
      }
    }
  }
}

.balanceTransfer {
  display: flex;
  align-items: center;
  align-items: center;
}

.balanceTransfer button {
  background-position: center;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMgNS4yNWg4bC0yLjE4Mi0yLjdNMTEgOC43NUgzbDIuMTgyIDIuNyIgc3Ryb2tlPSIjMDA5NEZGIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+");
}

.action {
  margin-top: 24px;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    border-radius: 8px;

    span {
      color: #fff !important;
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &__buy {
    background-color: #138850;
  }

  &__sell {
    background-color: #F43443;
  }
}

.signBtn {
  border-radius: 8px !important;
  font-size: 12px;
}

.error {
  span, p {
    color: rgba(255, 0, 0, 0.7) !important;
    opacity: 1;
  }
}

.errorText {
  color: #941F1F !important;
  display: block;
  text-align: center;
  margin-top: 4px;
}

@media only screen and (max-width: 743px) {
  .spotOrderForm {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
