.mainMenu {
  display: flex;
  align-items: center;
  margin: 0 50px;
  column-gap: 8px;
  justify-content: center;

  &__link {
    border-radius: 12px;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 195px;

    span {
      font-size: 18px;
      line-height: 16px;
    }
  }
}

.space {
  margin-top: 40px;
}

@media only screen and (max-width: 1024px) {
  .mainMenu {
    position: absolute;
    margin: 0 50px;
    width: calc(100% - 100px);
    bottom: 36px;
    z-index: 1;

    &__link {
      height: 36px;
      min-width: 81px;

      span {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .mainMenu__link:last-child {
    width: 114px;
    min-width: 114px;
  }

  .space {
    margin-top: 53px;
    position: inherit;
    bottom: 0;
    width: inherit;
  }
}

@media only screen and (max-width: 743px) {
  .mainMenu {
    position: inherit;
    width: 100%;
    overflow-x: auto;
    padding: 0 16px 10px 0;
    z-index: 1;
    margin: 0;
    justify-content: flex-start;
  }

  .space {
    margin-top: 32px;
  }
}