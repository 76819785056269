.spotOrdersList {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.list, .listLong {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 14px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    align-items: center;
    display: flex;
  }

  button {
    width: fit-content;
    position: relative;

    div {
      span {
        font-size: 8px;
      }
    }
  }

  button:has(div) {
    width: 50px;
  }
}

.cancelText {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: rgb(242, 54, 69) !important;
}

.listLong {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media only screen and (max-width: 743px) {
  .mobList {
    grid-template-columns: 1fr;
    grid-row-gap: 28px;
    text-align: end;
    padding: 18px;
    border-radius: 18px;
  }

  .mobHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    p {
      font-size: 18px;
      line-height: 16px;
      letter-spacing: -0.03em;
    }

    span {
      font-size: 12px;
      line-height: 11px;
      letter-spacing: -0.03em;
    }
  }

  .spotOrdersList {
    row-gap: 8px;
  }

  .mobList {
    &__table {
      padding-top: 14px;
      display: flex;
      flex-direction: column;
      row-gap: 28px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        opacity: 0.5;
        font-size: 12px;
        font-weight: 400;
        line-height: 11px;
        letter-spacing: -0.03em;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 11px;
        letter-spacing: -0.03em;
      }
    }
  }
}

