.register {
  padding: 90px 80px 130px 214px;
  display: flex;
  //height: 100vh;
  overflow: hidden;

  h3 {
    font-size: 40px;
    line-height: 44px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.leftBox {
  max-width: 370px;
  position: relative;
  z-index: 1;
}

.rightBox {
  position: relative;
}

.rightBox img {
  position: absolute;
  left: -135px;
  top: -290px;
  width: 1200px;
  height: auto;
}

.logo {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 900;
  font-family: "Audiowide", serif;
  display: block;
}

.subtitle {
  opacity: 0.5;
  font-size: 16px;
  line-height: 18px;
}

.field {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  position: relative;

  label {
    margin-top: 24px;
    color: #fff;
    font-weight: 500;
    line-height: 18px;
  }

  &__label {
    margin-top: 24px;
    color: #fff;
    font-weight: 500;
    line-height: 18px;
  }

  input {
    border: 1px solid #FFFFFF80;
    background-color: transparent;
    height: 55px;
    border-radius: 11px;
    padding-left: 20px;
    color: #fff ;
    font-size: 12px;
    line-height: 15px;
  }

  &:has(.error) input {
    border: 1px solid red;
  }
}

.error {
  position: absolute;
  bottom: -10px;
  font-size: 11px;
  line-height: 12px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #303953;
  padding: 6px;
  border-radius: 8px;
  max-width: 344px;
  width: 100%;
  text-align: center;
}

.action {
  margin-top: 48px;

  button {
    height: 54px;
    background-color: #33436D;
    width: 100%;
    border-radius: 13px;

    span {
      font-weight: 700;
      line-height: 18px;
    }
  }

  button:disabled {
    opacity: 0.4;
    cursor: unset;
  }
}

.toRegister {
  margin-top: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;

  span {
    font-size: 12px;
    opacity: 0.3;
  }

  a {
    font-size: 12px;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 1280px) {
  .register {
    padding: 90px 80px 130px 214px;
  }
}


@media only screen and (max-width: 1024px) {
  .register {
    padding: 70px 97px 50px 73px;

    h3 {
      font-size: 40px;
      line-height: 44px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  .logo {
    margin-bottom: 58px;
  }

  .rightBox img {
    position: absolute;
    left: -140px;
    top: -155px;
    width: 900px;
    height: auto;
  }

  .action {
    margin-top: 24px;
  }

  .leftBox {
    max-width: 300px;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .register {
    padding: 24px 32px;

    h3 {
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .logo {
    margin-bottom: 20px;
  }

  .leftBox {
    max-width: 100%;
    width: 100%;
  }

  .rightBox img {
    position: absolute;
    left: -140px;
    top: -155px;
    width: 900px;
    height: auto;
  }

  .field {
    input {
      height: 44px;
    }

    label {
      margin-top: 10px;
    }

    &__label {
      margin-top: 12px;
    }
  }

  .action {
    margin-top: 10px;

    button {
      height: 44px;
    }
  }

  .error {
    bottom: -12px;
    font-size: 10px;
    line-height: 11px;
  }
}