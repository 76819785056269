.wrapper {
  display: flex;
  justify-content: center;
  margin: 10px 0 20px;
}

.notification {
  max-width: 100%;
  display: flex;
  padding: 6px;
  column-gap: 8px;
  align-items: center;
  background-color: #303953;
  border-radius: 18px;
  border: 1px solid #941F1F;

  img {
    width: 20px;
  }

  p {
    font-size: 14px;
    line-height: 13px;

    a {
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .notification {
    padding: 6px;
    column-gap: 4px;
    align-items: center;
    background-color: #303953;
    border-radius: 18px;
    border: 1px solid #941F1F;

    img {
      width: 18px;
    }

    p {
      font-size: 13px;
      line-height: 13px;
    }
  }
}