.notificationContent {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.item {
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  max-width: 843px;

  &__header {
    display: flex;
    align-items: center;
    column-gap: 8px;

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    border-radius: 9px;
    background-color: #465683;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    opacity: .8;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
  }

  &__date {
    opacity: .5;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
  }
}

@media only screen and (max-width: 743px) {
  .item {
    padding: 10px 50px 16px 10px;
    row-gap: 8px;
    position: relative;

    &__header {
      flex-direction: column;
      row-gap: 8px;
      align-items: flex-start;
    }
  }

  .arrow {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}