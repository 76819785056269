.settAccount {
  max-width: 743px;

  &__skills {
    margin: 24px 0;
  }
}

.authBox {
  display: flex;
  align-items: center;
  column-gap: 24px;

  span  {
    font-size: 40px;
    line-height: 36px;
    font-weight: 500;
  }
}

.usePhoto {
  width: 42px;
  height: 42px;
  border-radius: 12px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  input {
    height: 50px;
    padding-left: 20px;
    font-weight: 500;
    width: 100%;
  }
}

.action {
  width: 100%;

  button {
    width: 100%;
    height: 50px;

    span {
      font-size: 18px;
      line-height: 16px;
      font-weight: 600;
    }
  }
}

.codeBox {
  border-radius: 18px;
  padding: 28px;

  h3 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px;
  }

  input {
    margin: 0 !important;
    border: 1px solid #2C4A7E !important;
    border-radius: 8px !important;
    background-color: transparent !important;
    color: #fff !important;
    width: 70px !important;
    height: 72px !important;
    padding-left: 28px !important;
    font-size: 24px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: -0.03em !important;
  }

  > div {
    display: flex !important;
    column-gap: 6px;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1024px) {
  .settAccount {
    max-width: 100%;
  }

  .codeBox {
    h3 {
      text-align: center;
    }

    > div {
      justify-content: center;
    }
  }
}
