.orderBookList {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  min-height: 172px;
}

.item {
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;

  p {
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
  }

  &__value {
    display: flex;
    align-items: center;
    column-gap: 12px;

    p {
      //width: 75px;
      text-align: end;
    }
  }
}

.labels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 22px;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  &__value {
    display: flex;
    align-items: center;
    column-gap: 12px;

    p {
      width: 75px;
    }

    p:last-child {
      text-align: end;
    }
  }
}

.bookMiddle {
  padding: 9px 12px;
  display: flex;
  align-items: center;
  column-gap: 15px;

  &__usdt {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    opacity: .3;
  }

  &__up, &__down {
    display: flex;
    align-items: center;
    column-gap: 4px;

    img {
      transition: all .15s;
    }
  }

  &__up {
    span {
      color: #20B26C;
    }

    img {
      transform: rotateZ(0deg);
    }
  }

  &__down {
    span {
      color: #FF5959;
    }

    img {
      transform: rotateZ(180deg);
    }
  }
}


@media only screen and (max-width: 1920px) {
  .orderBookList {
    min-height: 142px;
    overflow-x: hidden;
    //height: 152px;
  }
}