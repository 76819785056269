.swapLeftBox {
  h4 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin: 40px 0 20px;

  }
}

.swapFields, .swapFieldsWrong {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 13px;
  position: relative;
}

.swapFieldsWrong > div:first-child {
  border: 1px solid rgba(255, 0, 0, 0.7);
}

.value {
  opacity: .5;
  font-size: 12px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  margin-top: 40px;

  button {
    height: 50px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 14px !important;
    }

    &:disabled {
      opacity: 0.4;
    }
  }
}

.wrapper {
  margin-top: 20px;

  & > div > div {
    p {
      opacity: 1 !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .actions {
    button {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 743px) {
  .actions {
    margin-top: 24px;
  }

  .swapFields, .swapFieldsWrong {
    row-gap: 16px;
  }
}
