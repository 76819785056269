.followNews {
  margin: 173px -80px 240px 126px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: 48px;
    margin: 0 80px 48px 0;

    h2 {
      font-weight: 500;
      font-size: 64px;
    }
  }

  &__arrows {
    display: flex;
    align-items: center;
    column-gap: 11px;
    opacity: 0;
    pointer-events: none;

    button {
      padding: 10px;
      border: 1px solid #212121;
      border-radius: 10px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column-reverse;
  }
}

.blog {
  border: 1px solid #1B2846;
  border-radius: 40px;

  img {
    width: 100%;
    //height: 202px;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    padding: 20px;
    display: flex;
    justify-content: right;
  }

  &__right {
    display: flex;
    flex-direction: column;
    row-gap: 44px;
    width: 140px;

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
    }

    a {
      color: #33436D;
      display: inline-flex;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      position: relative;
      padding-right: 40px;
      width: 80px;

      &:after {
        content: '';
        position: absolute;
        top: 55%;
        right: 8px;
        transform: translateY(-50%) rotateZ(45deg);
        width: 9px;
        height: 9px;
        border-top: 2px solid #33436D;
        border-right: 2px solid #33436D;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .followNews {
    margin: 173px -80px 240px 66px;
  }

  .followNews__header {
    h2 {
      font-size: 44px;
    }
  }

  .blog {
    &__right {
      row-gap: 29px;
      width: 140px;

      p {
        font-size: 16px;
        line-height: 18px;
      }
    }

    &__content {
      padding: 16px;
    }
  }
}


@media only screen and (max-width: 1024px) {
  .followNews {
    margin: -20px -30px 160px 0;

    &__header {
      margin: 0 80px 24px 0;

      h2 {
        font-size: 32px;
      }
    }
  }

  .blog {
    &__right {
      width: 125px;

      p {
        font-size: 14px;
        line-height: 15px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .followNews {
    margin: -20px -30px 80px 0;
  }

  .followNews__header {
    margin: 0 0 24px 0;

    h2 {
      font-size: 24px;
    }
  }

  .blog {
    &__right {
      p {
        font-size: 14px;
        line-height: 15px;
      }
    }

    &__content {
      padding: 13px 13px 16px;
    }
  }
}