.processRange {
  width: 95%;
  margin: 24px auto 0;

  &__value {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 12px;
      opacity: 0.3;
      font-weight: 700;
    }
  }

  input {
    padding-left: 0;
    width: 100%;
    opacity: 0;
    height: 16px;
  }

  &__range {
    position: relative;
    height: 16px;
    margin-bottom: 10px;
  }
}

.labels {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: -0.03em;
    text-align: center;
  }
}