.accountSkills {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;

  li {
    padding: 8px;
    border-radius: 5px;
    background: #7CAA9445;
    max-height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;

    span {
      color: #C7C7C7;
      font-size: 16px;
      line-height: 14px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .accountSkills {
    li {
      padding: 6px;
      max-height: 23px;
      border-radius: 5px;
      display: flex;
      align-items: center;

      span {
        color: #C7C7C7;
        font-size: 13px;
        line-height: 12px;
      }
    }
  }
}