.spotOperationsChangers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 6px;
}

.boxHide {
  opacity: 0.3;
  pointer-events: none;
  cursor: inherit;
}

.operations {
  height: 37px;
  border-radius: 8px;
  display: flex;

  &__changerBuy, &__changerSell {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    p {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      border-radius: 8px;
    }
  }
}

.operations__changerBuy:has(input:checked) p {
  background-color: #138850;
  color: #fff;
}

.operations__changerSell:has(input:checked) p {
  background-color: #F43443;
  color: #fff;
}