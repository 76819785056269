.lightThm {
  a.active {
    text-decoration: underline;
  }

  .button {
    background-color: #2C4A7E;
    span {
      color: #fff;
    }
  }
}

.darkThm {
  a {
    opacity: 0.5;
  }
  a.active {
    opacity: 1;
  }
}