.mainContent {
  max-width: 1210px;
  margin: 35px auto 200px;
  padding: 0 90px;
}

.wrapper {

  & > div {
    margin-top: -8px;
    margin-bottom: 10px;
  }

  & > div > div {

    p {
      opacity: 1 !important;
    }
  }
}

.gift {
  padding: 16px 16px 16px 29px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 94px;

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 17px;
    }

    span {
      opacity: 0.5;
      line-height: 14px;
    }
  }

  &__fields {
    display: flex;
    align-items: center;
    column-gap: 16px;

    input {
      height: 40px;
      width: 289px;
      padding-left: 20px;
    }

    button {
      background-color: #5372A5;
      border-radius: 12px;
      height: 40px;
      width: 95px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        line-height: 14px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .mainContent {
    margin: 37px auto 80px;
  }
}

@media only screen and (max-width: 743px) {
  .mainContent {
    margin: 16px auto 80px;
    padding: 0 16px 36px;
  }
}