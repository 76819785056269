.verificationKYC {
  max-width: 840px;

  &__header {
    max-width: 531px;
    width: 100%;

    h2 {
      font-size: 40px;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 24px;
    }
  }

  &__content {
    margin-left: 40px;

    & > div {
      overflow: inherit;
    }
  }

  &__subTitle {
    font-size: 20px;
    line-height: 22px;
    opacity: 0.5;
    margin-bottom: 48px;
    max-width: 330px;
  }
}

.item {
  background: linear-gradient(338.31deg, #1D263E 16.47%, #374A7E 109.42%);
  border-radius: 14px;
  padding: 20px 20px 20px 20px;
  width: 100%;
  height: 100%;
  min-height: 512px;
  min-width: 270px;
  transition: all .15s;
  border: 1px solid #010104;
  cursor: pointer;

  p, span, li {
    color: #fff !important;
  }

  &:hover {
    background: linear-gradient(338.31deg, #212a40 16.47%, #465786 109.42%);
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  &__icon {
    margin-bottom: 26px;
    display: flex;
    justify-content: flex-start;

    img {
      width: 44px;
      height: 44px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 18px;
    text-align: start;
  }

  &__subTitle, &__requirementsTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 18px;
    text-align: start;
  }

  &__requirementsTitle {
    margin-bottom: 12px;
  }

  &__features {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        opacity: 0.5;
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__decor {
    margin: 18px 0;
    display: block;
    width: 100%;
    height: 1px;
    object-fit: contain;
  }

  &__requirements {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      opacity: 0.5;
      text-align: start;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .verificationKYC {

    &__content {
      margin-left: 0;
      padding-bottom: 60px;
    }

    &__subTitle {
      max-width: 100%;
    }

    &__subTitle {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 20px;
    }
  }

  .item {
    padding: 16px;
    min-height: 420px;
  }
}