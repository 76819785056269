.labels {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 24px;
  grid-column-gap: 21px;
  min-width: 576px;

  span {
    font-size: 14px;
    line-height: 12px;
    opacity: 0.3;
    white-space: nowrap;
    min-width: 139px;
  }
}

.balancesListWrapper {
  margin-top: 52px;
  overflow-y: auto;
}

.balancesList {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.balancesItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 21px;
  align-items: center;
  min-width: 576px;

  > * {
    min-width: 139px;
  }

  p, span {
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    white-space: nowrap;
  }

  &__coin {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
      width: 26px;
      height: 26px;
    }

    p {
      margin-right: 11px;
      font-size: 16px;
      font-weight: 500;
      line-height: 14px;
    }
  }

  &__usd {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
}

.opacity {
  opacity: 0.3;
}

.showMore {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .balancesListWrapper {
    margin-top: 32px;
  }

  .balancesList {
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 743px) {
  .balancesItem {
    &__coin {
      p {
        margin-right: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  .balancesList {
    row-gap: 24px;
  }
}