.main {

}

.subHeader {
  display: flex;
  flex-direction: column;
  column-gap: 64px;
}

@media only screen and (max-width: 620px) {
  .subHeader {
    margin-right: -16px;
  }
}
