.settingsMobHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 17px;
  }

  span {
    opacity: 0;
  }
}

@media only screen and (max-width: 743px) {
  .settingsMobHeader {
    margin-bottom: 33px;
  }
}