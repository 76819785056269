.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  border-radius: 11px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.dark {
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    border: 1px solid #FFFFFF80;
    padding: 12px 20px;
    height: 24px;
  }
}

.light {
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    border: 1px solid #C9CEDE;
    color: #333F61;
    padding: 12px 20px;
    height: 24px;
  }
}

body:has(.dark) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
    border: 1px solid #FFFFFF80;
    border-radius: 11px;
    background-color: #171E33;
    color: #fff;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #333F61;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #2359B8;
  }
}

body:has(.light) {
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root p span {
    color: #333F61 !important;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper {
    border: 1px solid #C9CEDE;
    border-radius: 11px;
    background-color: #fff;
    color: #333F61 !important;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: rgb(83, 114, 165);
    p span {
      color: #fff !important;
    }
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected span {
    color: #333F61;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #EDF3FF;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover p span {
    color: #333F61 !important;
  }

  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
    background-color: rgb(83, 114, 165);

    p span {
      color: #fff !important;
    }
  }
}