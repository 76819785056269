.quantityTotalBox {
  display: flex;
  column-gap: 4px;
  position: relative;
}

.priceInputBox {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 8px 6px;
  background-color: #201D2B;
  border-radius: 8px;
  width: 100%;
  border: 1px solid transparent;

  label {
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    opacity: .4;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    opacity: .4;
  }

  &__field {
    display: flex;
    justify-content: space-between;
  }

  input {
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    width: 100%;
  }
}

.minimumError {
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  text-align: center;
  width: 100%;
  color: rgb(255, 89, 89) !important;
}

.error {
  border: 1px solid rgba(255, 0, 0, 0.7);

  input {
    color: rgba(255, 0, 0, 0.7) !important;
  }
}