.orderRecentWrapper {
  //position: relative;
}

.spotBookSplitWrapper {
  display: flex;
  column-gap: 8px;
}

.splitBox, .splitBoxSecond {
  padding: 10px 0;
  background-color: #16121F;
  border-radius: 16px;
  min-width: 300px;
  position: relative;
}

.splitBoxSecond {
  padding-top: 50px;
}

.header {
  padding: 12px 16px 8px;
}

.content {
  height: 37px;
  border-radius: 8px;
  display: flex;


  &__changer, &__changerLight {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    p {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      border-radius: 8px;
    }
  }
}

.content__changer:has(input:checked) p {
  background-color: #485A8E;
}

.content__changerLight:has(input:checked) p {
  background-color: #3866B7;
  color: #fff;
}