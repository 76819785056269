.history {
  display: flex;
  justify-content: center;
  column-gap: 18px;
  margin-top: 32px;
  margin-bottom: 200px;
  padding: 0 65px;
}

.historyBox {
  max-width: 100%;
  width: 100%;
  border-radius: 20px;
  padding: 40px;
  position: relative;
}

.filterBtn {
  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    justify-content: space-between;
    height: 45px;
    padding: 0 8px 0 16px;
    align-items: center;
    border-radius: 8px;
    width: 100%;
  }
}

.showMore {
  display: flex;
  justify-content: center;
  margin-top: 12px;

  &__button {
    padding: 0 8px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 1280px) {
  .history {
    padding: 0 30px;
  }

  .historyBox {
    padding: 20px;
  }
}

@media only screen and (max-width: 743px) {
  .history {
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 100px;
  }

  .historyBox {
    padding: 0;
    background-color: transparent !important;
  }
}