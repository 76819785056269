.spotFooter {
  margin-top: 8px;
  border-radius: 16px;
}

.header {
  padding: 14px;
  border-radius: 12px;
  display: flex;
  column-gap: 20px;
  position: relative;
  z-index: 2;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  &__trade {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }
}

.formSkeleton {
  position: absolute;
  left: 0;
  top: -16px;
  width: 100%;
  border-radius: 16px;
  display: block;
  height: calc(100% + 16px);
  opacity: 0.8;
  //background: linear-gradient(45deg, rgb(8, 9, 28), #16121F, #332349);
  background-size: 300% 300% !important;
  //animation: gradient 2.5s ease infinite;
}

.content {
  padding: 20px 32px;
  position: relative;

  &__header, &__headerHide, &__headerLong {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 14px;
  }

  &__headerLong {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  &__headerHide {
    opacity: .3;
    pointer-events: none;
  }
}

.lock {
  display: flex;
  margin: 45px auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__image {
    width: 44px;
    height: 44px;
    background-color: #25203F;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 24px;
  }
}

.auth {
  display: flex;
  column-gap: 4px;
  align-items: center;

  a {
    height: 38px;

    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding: 0 8px;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      white-space: nowrap;
    }
  }
}

.login {
  background-color: #313040;
}

.signUp {
  background-color: #2359B8;
}

.activeTab {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
}

@media only screen and (max-width: 743px) {
  .spotFooter {
    padding-bottom: 80px;
  }

  .header, .headerLong {
    overflow-x: auto;
    column-gap: 20px;
    border-radius: initial;

    p, button {
      white-space: nowrap;
    }
  }

  .content {
    padding: 0;

    &__header, &__headerHide {
      opacity: .5;
      grid-template-columns: 1fr;
      grid-row-gap: 28px;
      padding-top: 15px;
    }
  }
}