.swap {
  display: flex;
  justify-content: center;
  column-gap: 18px;
  margin-top: 60px;
  padding: 0 65px 240px;

  &__leftBox {
    max-width: 80%;
    width: 100%;
    padding: 22px 16px;
    background-color: #171E33;
    border-radius: 20px;
  }

  &__rightBox {
    max-width: 568px;
    width: 100%;
    padding: 40px;
    background-color: #171E33;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .swap {
    padding: 0 90px 140px;
    flex-direction: column;
    row-gap: 40px;

    &__leftBox {
      background-color: transparent !important;
      padding: 0;
      max-width: 100%;
    }

    h3 {
      font-size: 40px;
      line-height: 44px;
    }
  }

}

@media only screen and (max-width: 743px) {
  .swap {
    padding: 0 16px 70px;
    margin-top: 24px;

    &__leftBox {
      padding: 0;
    }
  }
}
