.disconnect2FA {

}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 1px;
  max-width: 484px;
  margin-bottom: 14px;
}

.themeWrapper {
  padding: 16px 16px;
  background-color: #131B38;
  width: 484px;
  border-radius: 18px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changer {
  cursor: pointer;
}

.theme {
  display: flex;
  align-items: center;
  column-gap: 10px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}

.changer__switcher {
  display: flex;
  align-items: center;
}

.switcherBox, .switcherBoxActive {
  width: 46px;
  height: 28px;
  border-radius: 50px;
  background-color: #78788029;
  position: relative;
  transition: all .2s;
}

.switcherFalse, .switcherTrue {
  width: 24px;
  height: 24px;
  background-color: #fff;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 3px 1px 0px #0000000F;
  box-shadow: 0px 3px 8px 0px #00000026;
  left: 1px;
  top: 2px;
  transition: all .2s;
}

.switcherTrue {
  left: calc(100% - 27px);
}

.loader span {
  font-size: 6px;
}

.fieldCode, .fieldCodeShow, .fieldCodeHide {
  padding: 16px;
  max-width: 484px;
  margin: 14px 0 14px;
  border-radius: 18px;

  &__title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px;
    padding-left: 32px;
  }
  input {
    margin: 0 !important;
    border: 1px solid #2C4A7E !important;
    border-radius: 16px !important;
    background-color: transparent !important;

    width: 60px !important;
    height: 62px !important;
    padding-left: 19px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  > div {
    display: flex !important;
    column-gap: 6px;
    justify-content: center;
  }
}

.action {
  button {
    height: 50px;
    width: 100%;
    max-width: 484px;

    &:disabled {
      opacity: 0.4;
    }
  }
}

@media only screen and (max-width: 743px) {
  .themeWrapper {
    width: 100%;
  }

  .fieldCode, .fieldCodeShow, .fieldCodeHide {
    padding: 6px;

    &__title {
      padding-left: 0;
    }
    input {
      width: 50px !important;
      height: 52px !important;
    }
  }
}