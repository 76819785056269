.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  height: 31px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    height: 23px;
  }
}

@media only screen and (max-width: 1024px) {
  body:has(.popupBox) {
    overflow: hidden !important;
  }
}