.spotTotalChanger {
  cursor: pointer;
  position: relative;
}

.spotHeaderToken {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 0 18px;
  min-width: 155px;
}

.content, .contentActive {
  position: absolute;
  left: 0;
  top: 40px;
  padding: 1px 0;
  border-radius: 8px;
  z-index: 2;
  height: 0;
  opacity: 0;
  transition: 0.15s;
  overflow: hidden;
}

.contentActive {
  position: absolute;
  left: 0;
  top: 40px;
  padding: 10px 0;
  border-radius: 8px;
  z-index: 2;
  height: auto;
  opacity: 1;
  pointer-events: all;
}

.coinIcon {
  width: 24px;
  height: 24px;
}

.coinInfo, .coinInfoLight {
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  h6 {
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;

    span {
      opacity: .5;
    }
  }

  p {
    white-space: nowrap;
    opacity: .5;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}

.coinInfoLight {
  h6, h6 span, p, p span {
    color: #fff !important;

  }
}

.item, .itemLight {
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background-color: #171E33;
  }
}

.itemLight {
  &:hover {
    background-color: #EDF3FF;
  }
}

@media only screen and (max-width: 743px) {
  .contentActive {
    max-height: 340px;
    overflow: auto;
  }

  .spotHeaderToken {
    padding: 0;
  }
}
