.stakingOrders {

}

.valuesDecor {
  width: 100%;
}

.stakingOrderList {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.order {
  padding: 16px;
  border-radius: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }

  &__labels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 24px;

    p {
      text-align: center;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    p {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .order {
    &__labels {
      p {
        font-size: 14px;
      }
    }

    &__content {
      p {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 743px) {
  .order {
    &__labels {
      grid-template-columns: 1fr;
      grid-row-gap: 6px;
      margin-bottom: 0;

      p {
        height: 20px;
        text-align: start;
      }
    }

    &__content {
      grid-template-columns: 1fr;
      grid-row-gap: 6px;
      margin-bottom: 0;

      p {
        height: 20px;
        text-align: end;
      }
    }

    &__box {
      display: flex;
      justify-content: space-between;
    }
  }
}