.user {
  padding: 24px 0;
  display: flex;
  column-gap: 50px;
  max-width: 1030px;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;

  &__decor {
    width: 2px;
    background-color: #fff;
    opacity: 0.08;
    display: block;
  }
}

.userSlider {
  margin: 30px 0 0;
}

.wrapper {
  margin-top: -30px;

  & > div > div {
    p {
      opacity: 1 !important;
    }
  }
}

@media only screen and (max-width: 1580px) {
  .user {
    column-gap: 40px;
  }
}

@media only screen and (max-width: 1360px) {
  .user {
    column-gap: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    margin-top: 0;

    & > div > div {
      background-color: #5372A5 !important;

      p {
        opacity: 1 !important;
      }
    }
  }
}

@media only screen and (max-width: 743px) {
  .userSlider {
    margin: 24px 0 0;
  }
}