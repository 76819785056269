.order .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0;
  border: none;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding-left: 12px;
    padding-top: 1px;
  }
}

.order .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1en12rq-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  width: 100px;
  min-width: 100px;
  cursor: pointer;
  background-color: #313040;
}

.order .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1en12rq-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root svg {
  position: absolute;
  right: 10px;
  pointer-events: none;
}

.order .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input p {
  padding-right: 24px;
}

.orderBook-item {
  display: flex;
  justify-content: center;
  width: 100%;
}