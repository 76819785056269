.landingFooter, .landingFooterMain {
  padding: 0 126px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  //margin-bottom: 180px;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;

  &__column, &__columnMain {
    position: relative;
    z-index: 2;

    h5 {
      font-size: 32px;
      line-height: 63px;
      font-weight: 500;
      margin-bottom: 32px;
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      li {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        opacity: 0.5;
      }
    }
  }

  &__columnMain {
    h5 {
      font-size: 20px;
    }

    ul li {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.logo {
  position: relative;
}

.landingFooterMain {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 112px 126px 68px;


  img {
    height: 23px;
    margin-top: 23px;
  }
}

.leftDecor {
  position: absolute;
  bottom: -120%;
  left: 0;
  transform: translateX(-50%);
  z-index: 1;
  width: 130%;
  height: 460%;
  pointer-events: none;
}

.rightDecor {
  position: absolute;
  bottom: -60%;
  left: 50%;
  z-index: 1;
  width: 110%;
  height: 400%;
  pointer-events: none;
}

@media only screen and (max-width: 1280px) {
  .leftDecor {
    bottom: -180%;
  }

  .rightDecor {
    left: 70%;
  }
}

@media only screen and (max-width: 1100px) {
  .landingFooter {
    padding: 0;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 127px;
    margin-bottom: 130px;
  }

  .leftDecor {
    position: absolute;
    bottom: -40%;
    left: 0;
    transform: translateX(-50%);
    z-index: 1;
    width: 150%;
    height: 174%;
    pointer-events: none;
  }

  .rightDecor {
    position: absolute;
    bottom: -28%;
    left: 40%;
    z-index: 1;
    width: 155%;
    height: 205%;
    pointer-events: none;
  }
}

@media only screen and (max-width: 900px) {
  .landingFooter {
    padding: 0;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 127px;
    margin-bottom: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .landingFooter {
    padding: 0;
    grid-template-columns: 1fr;
    grid-row-gap: 64px;

    &__column {
      h5 {
        font-size: 24px;
      }

      ul {
        row-gap: 24px;

        li {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    .leftDecor {
      position: absolute;
      bottom: -18%;
      left: 0;
      transform: translateX(-50%);
      z-index: 1;
      width: 160%;
      height: 80%;
      pointer-events: none;
    }

    .rightDecor {
      position: absolute;
      bottom: 18%;
      left: 40%;
      z-index: 1;
      width: 155%;
      height: 100%;
      pointer-events: none;
    }
  }
}

@media only screen and (max-width: 767px) {
  .landingFooter {
    margin-bottom: 70px;
  }
}