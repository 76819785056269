.withdrawLeftBox {
  h4 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }
}

.subtitle {
  max-width: 310px;
  font-size: 12px;
  line-height: 13px;
  opacity: 0.5;
  margin-bottom: 20px;
}

.secondTitle {
  margin-top: 16px;
}

.action {
  margin: 20px 0;

  button {
    width: 100%;
    height: 50px;
    border-radius: 8px !important;

    &:disabled {
      opacity: .4;
    }

    span {
      font-size: 16px;
      line-height: 14px;
    }
  }
}

@media only screen and (max-width: 743px) {
  .secondTitle {
    margin-top: 16px;
  }

  .action {
    margin: 20px 0 20px;
  }
}